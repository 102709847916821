import React, { createContext, useState, useCallback } from "react";
import config from "../config";

export const EnvironmentContext = createContext();

export const EnvironmentProvider = ({ children }) => {
  const [env, setEnv] = useState(config.env);

  const switchEnvironment = useCallback(
    (newEnv) => {
      // If no argument is provided, cycle between 'prod', 'staging', and 'dev'
      if (newEnv === undefined) {
        switch (env) {
          case "prod":
            newEnv = "staging";
            break;
          case "staging":
            newEnv = "dev";
            break;
          default: // dev
            newEnv = "prod";
        }
      }
      // Update the API route
      config.apiBaseUrl = config.apiBaseUrl.replace(/\/(dev|staging)$/, ""); // Remove /dev or /staging if they exist
      if (
        (newEnv === "dev" || newEnv === "staging") &&
        !config.apiBaseUrl.endsWith(`/${newEnv}`)
      ) {
        config.apiBaseUrl += `/${newEnv}`;
      }
      // Update the environment
      config.env = newEnv;
      setEnv(newEnv);
    },
    [env]
  );

  return (
    <EnvironmentContext.Provider value={{ env, switchEnvironment }}>
      {children}
    </EnvironmentContext.Provider>
  );
};
