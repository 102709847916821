import React, { useContext } from "react";
import { EnvironmentContext } from "../contexts/EnvironmentContext";
import Select from "react-select";

const EnvSwitcher = ({ onEnvironmentSwitch }) => {
  const { env = "prod", switchEnvironment } = useContext(EnvironmentContext);

  const currentOption = {
    value: env,
    label:
      env === "prod"
        ? "Production"
        : env === "staging"
          ? "Staging"
          : "Development",
  };

  const options = [
    { value: "prod", label: "Production" },
    { value: "staging", label: "Staging" },
    { value: "dev", label: "Development" },
  ].filter((option) => option.value !== env);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor:
        env === "dev"
          ? "#dc3545"
          : env === "staging"
            ? "#fd7e14"
            : env === "prod"
              ? "#28a745"
              : provided.backgroundColor,
      borderColor: "transparent",
      "&:hover": {
        borderColor: "transparent",
      },
      fontFamily: "'Jost', sans-serif",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
      fontFamily: "'Jost', sans-serif",
      fontWeight: "500",
    }),
    option: (provided) => ({
      ...provided,
      fontFamily: "'Jost', sans-serif",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const handleChange = (selectedOption) => {
    switchEnvironment(selectedOption.value);
    if (onEnvironmentSwitch) {
      if (typeof onEnvironmentSwitch === "function") {
        onEnvironmentSwitch(selectedOption.value);
      }
    }
  };

  return (
    <Select
      value={currentOption}
      onChange={handleChange}
      options={options}
      styles={customStyles}
      isSearchable={false}
    />
  );
};

export default EnvSwitcher;
