import React, { useState, useEffect } from "react";
import "../App.css";
import config from "../config";
import { FaSpinner } from "react-icons/fa";
import Select from "react-select";

function Agents({
  run,
  onAgentClick,
  selectedObject,
  googleAuthToken,
  onViewChange,
}) {
  const [agents, setAgents] = useState([]);
  const [observables, setObservables] = useState([]);
  const [outputs, setOutputs] = useState([]);
  const [errors, setErrors] = useState([]);
  const [identifiers, setIdentifiers] = useState([]);
  const [consoleAgents, setConsoleAgents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadedViews, setLoadedViews] = useState(new Set());
  const [view, setView] = useState("agents");

  // Define view options for the dropdown
  const viewOptions = [
    { value: "agents", label: "Agents" },
    { value: "consoleAgents", label: "Console Agents" },
    { value: "observables", label: "Observables" },
    { value: "identifiers", label: "Identifiers" },
    { value: "outputs", label: "Outputs" },
    { value: "errors", label: "Errors" },
  ];

  // Handle select change
  const handleViewChange = (selectedOption) => {
    setView(selectedOption.value);
    if (!loadedViews.has(selectedOption.value) && run) {
      fetchViewData(selectedOption.value);
    }
  };

  // Function to fetch data for a specific view
  const fetchViewData = async (viewType) => {
    if (!run) return;

    setLoading(true);
    try {
      let endpoint;
      let setData;

      switch (viewType) {
        case "agents":
          endpoint = `${config.apiBaseUrl}/agents/${run.id}`;
          setData = setAgents;
          break;
        case "observables":
          endpoint = `${config.apiBaseUrl}/observables/${run.id}`;
          setData = setObservables;
          break;
        case "outputs":
          endpoint = `${config.apiBaseUrl}/workflow-outputs/${run.id}`;
          setData = setOutputs;
          break;
        case "errors":
          endpoint = `${config.apiBaseUrl}/workflow-errors/${run.id}`;
          setData = setErrors;
          break;
        case "identifiers":
          endpoint = `${config.apiBaseUrl}/workflow-identifiers/${run.id}`;
          setData = setIdentifiers;
          break;
        case "consoleAgents":
          endpoint = `${config.apiBaseUrl}/console-agents/${run.id}`;
          setData = setConsoleAgents;
          break;
        default:
          return;
      }

      const response = await fetch(endpoint, {
        headers: { Authorization: `Bearer ${googleAuthToken}` },
      });

      const data = await response.json();
      setData(data);

      // Mark this view as loaded
      setLoadedViews((prev) => new Set([...prev, viewType]));
    } catch (error) {
      console.error(`Error fetching ${viewType} data:`, error);
    } finally {
      setLoading(false);
    }
  };

  // Initial load effect for current view
  useEffect(() => {
    if (run) {
      // Clear loaded views when run changes
      setLoadedViews(new Set());
      // Reset all data states
      setAgents([]);
      setObservables([]);
      setOutputs([]);
      setErrors([]);
      setIdentifiers([]);
      setConsoleAgents([]);
      // Load data for current view
      fetchViewData(view);
    }
  }, [run, googleAuthToken, view]);

  useEffect(() => {
    onViewChange(view);
  }, [view, onViewChange]);

  // Pass the observable runs when clicking an observable
  const handleObjectClick = (item) => {
    if (!item || !run) {
      console.warn("Attempted to click on an item with missing data:", item);
      return;
    }

    if (item.function_name) {
      // For observables, pass the runs data
      onAgentClick({
        ...item,
        runs: item.runs || [], // Include the runs data if it exists
      });
    } else if (item.output) {
      // For outputs, pass the runs data
      onAgentClick({
        ...item,
        runs: item.runs || [], // Include the runs data if it exists
      });
    } else if (item.error) {
      // For errors, pass the runs data
      onAgentClick({
        ...item,
        runs: item.runs || [], // Include the runs data if it exists
      });
    } else if (item.identifier_name) {
      // For identifiers
      onAgentClick({
        ...item,
        type: "identifier",
        id: item.identifier_id || item.value_id, // Use a valid ID for API calls
        runs: [], // Add empty runs array to maintain consistency
        workflow_instance_id: run.id, // Add workflow instance ID
      });
    } else if (item.name && view === "consoleAgents") {
      // For console agents - updated to match console_agents table schema
      onAgentClick({
        ...item,
        type: "consoleAgent",
        runs: [], // Add empty runs array to maintain consistency
        workflow_instance_id: run.id, // Add workflow instance ID
        // Use the fields that exist in the console_agents table
        agent_name: item.name, // Use name field from DB
        message: item.description || "", // Use description as message content
      });
    } else {
      // For regular agents, pass as is
      onAgentClick(item);
    }
  };

  if (loading) {
    return (
      <div className="agents-container">
        <div className="header-with-toggle">
          <Select
            value={viewOptions.find((option) => option.value === view)}
            onChange={handleViewChange}
            options={viewOptions}
            className="view-selector"
            classNamePrefix="select"
            isSearchable={false}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                cursor: "pointer",
                fontFamily: "'Jost', sans-serif",
                fontWeight: "500",
              }),
              option: (baseStyles) => ({
                ...baseStyles,
                fontFamily: "'Jost', sans-serif",
              }),
              singleValue: (baseStyles) => ({
                ...baseStyles,
                fontFamily: "'Jost', sans-serif",
                fontWeight: "500",
              }),
              menu: (baseStyles) => ({
                ...baseStyles,
                zIndex: 9999,
              }),
            }}
          />
        </div>
        <div className="loading-container">
          <FaSpinner className="loading-icon" />
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="agents-container">
      <div className="header-with-toggle" style={{ marginBottom: "20px" }}>
        <Select
          value={viewOptions.find((option) => option.value === view)}
          onChange={handleViewChange}
          options={viewOptions}
          className="view-selector"
          classNamePrefix="select"
          isSearchable={false}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              cursor: "pointer",
              fontFamily: "'Jost', sans-serif",
              fontWeight: "500",
            }),
            option: (baseStyles) => ({
              ...baseStyles,
              fontFamily: "'Jost', sans-serif",
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              fontFamily: "'Jost', sans-serif",
              fontWeight: "500",
            }),
            menu: (baseStyles) => ({
              ...baseStyles,
              zIndex: 9999,
            }),
          }}
        />
      </div>
      <div className="agents-list">
        {view === "observables" ? (
          observables.length > 0 ? (
            observables.map((observable) => (
              <button
                key={observable.id}
                onClick={() => handleObjectClick(observable)}
                className={`agent ${
                  selectedObject && selectedObject.id === observable.id
                    ? "selected"
                    : ""
                }`}
              >
                <strong>
                  {observable.function_name
                    ? observable.function_name
                        .replace(/_/g, " ")
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")
                    : "Unnamed Observable"}
                </strong>
              </button>
            ))
          ) : (
            <div className="agent-placeholder">
              No observables found for this run
            </div>
          )
        ) : view === "outputs" ? (
          outputs.length > 0 ? (
            outputs.map((output) => (
              <button
                key={output.id}
                onClick={() => handleObjectClick(output)}
                className={`agent ${
                  selectedObject && selectedObject.id === output.id
                    ? "selected"
                    : ""
                }`}
              >
                <strong>
                  {(output.output || "Unnamed Output").slice(0, 22) +
                    (output.output && output.output.length > 25 ? "..." : "")}
                </strong>
                <p>{output.description}</p>
                {output.key && (
                  <p
                    style={{
                      fontSize: "0.8em",
                      color: "#666",
                      marginTop: "4px",
                      marginBottom: "0",
                    }}
                  >
                    {output.key}
                  </p>
                )}
              </button>
            ))
          ) : (
            <div className="agent-placeholder">
              No outputs found for this run
            </div>
          )
        ) : view === "errors" ? (
          errors.length > 0 ? (
            errors.map((error) => (
              <button
                key={error.id}
                onClick={() => handleObjectClick(error)}
                className={`agent ${
                  selectedObject && selectedObject.id === error.id
                    ? "selected"
                    : ""
                }`}
              >
                <strong>
                  {(error.error || "Unnamed Error").slice(0, 22) +
                    (error.error.length > 25 ? "..." : "")}
                </strong>
                <p>{error.details}</p>
              </button>
            ))
          ) : (
            <div className="agent-placeholder">
              No errors found for this run
            </div>
          )
        ) : view === "identifiers" ? (
          identifiers.length > 0 ? (
            identifiers.map((identifier) => (
              <button
                key={identifier.identifier_id}
                onClick={() => handleObjectClick(identifier)}
                className={`agent ${
                  selectedObject &&
                  selectedObject.identifier_id === identifier.identifier_id
                    ? "selected"
                    : ""
                }`}
              >
                <strong>
                  {identifier.identifier_name || "Unnamed Identifier"}
                </strong>
                {identifier.identifier_value && (
                  <p>{identifier.identifier_value}</p>
                )}
              </button>
            ))
          ) : (
            <div className="agent-placeholder">
              No identifiers found for this run
            </div>
          )
        ) : view === "consoleAgents" ? (
          consoleAgents.length > 0 ? (
            consoleAgents.map((consoleAgent) => (
              <button
                key={consoleAgent.id}
                onClick={() => handleObjectClick(consoleAgent)}
                className={`agent ${
                  selectedObject && selectedObject.id === consoleAgent.id
                    ? "selected"
                    : ""
                }`}
              >
                <strong>
                  {consoleAgent.agent_name
                    ? consoleAgent.agent_name
                        .replace(/_/g, " ")
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")
                    : consoleAgent.name
                      ? consoleAgent.name
                          .replace(/_/g, " ")
                          .split(" ")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")
                      : "Unnamed Console Agent"}
                </strong>
              </button>
            ))
          ) : (
            <div className="agent-placeholder">
              No console agents found for this run
            </div>
          )
        ) : agents.length > 0 ? (
          agents.map((agent) => {
            // Clean agent name
            let cleanedName = agent.agent_name
              ? agent.agent_name
                  .replace(/_/g, " ")
                  .split(" ")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ")
              : "Unknown Agent";

            return (
              <button
                key={agent.id}
                onClick={() => handleObjectClick(agent)}
                className={`agent ${
                  selectedObject && selectedObject.id === agent.id
                    ? "selected"
                    : ""
                }`}
              >
                <strong>{cleanedName}</strong>
              </button>
            );
          })
        ) : (
          <div className="agent-placeholder">No agents found for this run</div>
        )}
      </div>
    </div>
  );
}

export default Agents;
