import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from "react";
import axios from "axios";
import { EnvironmentContext } from "../contexts/EnvironmentContext";
import config from "../config";
import EnvSwitcher from "./EnvSwitcher";
import { FaRegCopy, FaCheck, FaTimes, FaGripVertical } from "react-icons/fa";
import { BiRefresh } from "react-icons/bi";

// Replace react-beautiful-dnd with @dnd-kit imports
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const WORKFLOW_STATES = ["active", "paused", "archived"];

function SortableMetricRow({
  metric,
  formatId,
  copyToClipboard,
  env,
  setEditingMetric,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: metric.id,
    disabled: env !== "dev", // Disable sorting if not in dev environment
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: isDragging ? "#f0f8ff" : undefined,
    boxShadow: isDragging ? "0 0 10px rgba(0, 0, 0, 0.15)" : undefined,
    opacity: isDragging ? 0.8 : 1,
  };

  return (
    <tr ref={setNodeRef} style={style}>
      {env === "dev" && (
        <td
          {...attributes}
          {...listeners}
          style={{ cursor: "grab", textAlign: "center" }}
        >
          <FaGripVertical />
        </td>
      )}
      <td>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {formatId(metric.id)}
          <button
            onClick={(e) => copyToClipboard(metric.id, e)}
            style={{
              border: "none",
              background: "none",
              cursor: "pointer",
              padding: "4px",
              display: "flex",
              alignItems: "center",
            }}
            title="Copy full ID"
            type="button"
          >
            <FaRegCopy size={14} />
          </button>
        </div>
      </td>
      <td>{metric.metric_name}</td>
      <td>{metric.metric_definition || "-"}</td>
      <td>{metric.metric_type}</td>
      <td>{metric.os_metric || "-"}</td>
      <td>{metric.metric_aggregation}</td>
      <td>{metric.time_interval}</td>
      {env === "dev" && (
        <td>
          <button
            onClick={() => setEditingMetric(metric)}
            style={{
              border: "none",
              background: "none",
              cursor: "pointer",
              padding: "4px",
            }}
            title="Edit metric"
            type="button"
          >
            Edit
          </button>
        </td>
      )}
    </tr>
  );
}

function WorkflowConfig({ googleAuthToken }) {
  const [customers, setCustomers] = useState([]);
  const [workflowDetailsWorkflows, setWorkflowDetailsWorkflows] = useState([]);
  const [metricWorkflows, setMetricWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState("");
  const [workflowDescription, setWorkflowDescription] = useState("");
  const [workflowCustomerId, setWorkflowCustomerId] = useState("");
  const [workflowDisplayName, setWorkflowDisplayName] = useState("");
  const [hasDescriptionChanged, setHasDescriptionChanged] = useState(false);
  const [hasDisplayNameChanged, setHasDisplayNameChanged] = useState(false);
  const [updateResult, setUpdateResult] = useState("");
  const [workflowState, setWorkflowState] = useState("");
  const [hasStateChanged, setHasStateChanged] = useState(false);
  const [metricName, setMetricName] = useState("");
  const [metricDefinition, setMetricDefinition] = useState("");
  const [isOsMetric, setIsOsMetric] = useState(false);
  const [osMetricType, setOsMetricType] = useState("");
  const [metricType, setMetricType] = useState("");
  const [metricAggregation, setMetricAggregation] = useState("");
  const [timeInterval, setTimeInterval] = useState("");
  const [metricFormat, setMetricFormat] = useState("");
  const [metricResult, setMetricResult] = useState("");
  const [metricsCustomerId, setMetricsCustomerId] = useState("");
  const [existingMetrics, setExistingMetrics] = useState([]);
  const [selectedMetricWorkflow, setSelectedMetricWorkflow] = useState("");
  const [metricIdToDelete, setMetricIdToDelete] = useState("");
  const [deleteMetricResult, setDeleteMetricResult] = useState("");
  const [observables, setObservables] = useState([]);
  const [selectedObservable, setSelectedObservable] = useState("");
  const [actions, setActions] = useState([]);
  const [showAllActions, setShowAllActions] = useState(false);
  const [selectedAction, setSelectedAction] = useState("");
  const [editingMetric, setEditingMetric] = useState(null);
  const [editMetricResult, setEditMetricResult] = useState("");
  const [migrateMetricResult, setmigrateMetricResult] = useState("");
  const [metricsSyncStatus, setMetricsSyncStatus] = useState({
    loading: false,
    synced: null,
  });
  const [workflowSyncStatus, setWorkflowSyncStatus] = useState({
    loading: false,
    synced: null,
  });
  const [migrateWorkflowResult, setMigrateWorkflowResult] = useState("");
  const [metricsOrderChanged, setMetricsOrderChanged] = useState(false);
  const [saveOrderResult, setSaveOrderResult] = useState("");
  const [originalMetricsOrder, setOriginalMetricsOrder] = useState([]);
  const [showAllObservables, setShowAllObservables] = useState(false);

  const { env, switchEnvironment } = useContext(EnvironmentContext);

  // Add constants for dropdown options
  const OS_METRIC_TYPES = ["instance", "observable", "action"];
  const METRIC_TYPES = [
    "line_chart",
    "bar_chart",
    "stacked_bar_chart",
    "table",
  ];
  const METRIC_AGGREGATIONS = ["sum", "count", "average", "min", "max"];
  const TIME_INTERVALS = ["hour", "day", "week", "month", "year"];

  // Add constant for metric format options
  const METRIC_FORMATS = {
    0: "(e.g., 1235)",
    "0.0": "(e.g., 1234.5)",
    "0.00": "(e.g., 1234.56)",
    "#,##0": "(e.g., 1,235)",
    "#,##0.00": "(e.g., 1,234.56)",
  };

  // Add this ref and useEffect to fix react-beautiful-dnd in React 18
  const strictModeFixApplied = useRef(false);

  useEffect(() => {
    // This is a workaround for react-beautiful-dnd not working in React 18 / StrictMode
    if (!strictModeFixApplied.current) {
      const originalAnnounce = window.HTMLElement.prototype.announce;

      // Do nothing in the announce method to fix the issue
      window.HTMLElement.prototype.announce = function () {
        return;
      };

      setTimeout(() => {
        window.HTMLElement.prototype.announce = originalAnnounce;
      }, 500);

      strictModeFixApplied.current = true;
    }
  }, []);

  function resetAllStates() {
    // Reset all message states
    setUpdateResult("");
    setMetricResult("");
    setDeleteMetricResult("");
    setEditMetricResult("");
    setmigrateMetricResult("");
    setMigrateWorkflowResult("");

    // Reset all select/input states
    setSelectedWorkflow("");
    setWorkflowDescription("");
    setWorkflowDisplayName("");
    setWorkflowState("");
    setSelectedMetricWorkflow("");
    setMetricName("");
    setMetricDefinition("");
    setIsOsMetric(false);
    setOsMetricType("");
    setSelectedObservable("");
    setSelectedAction("");
    setMetricType("");
    setMetricAggregation("");
    setTimeInterval("");
    setMetricFormat("");
    setMetricIdToDelete("");
    setEditingMetric(null);
    setMetricsSyncStatus({ loading: false, synced: null });
    setWorkflowSyncStatus({ loading: false, synced: null });
    setMetricsOrderChanged(false);
    setSaveOrderResult("");
    // Reset arrays - now with separate workflow states
    setWorkflowDetailsWorkflows([]);
    setMetricWorkflows([]);
    setExistingMetrics([]);
    setObservables([]);
    setActions([]);
    setOriginalMetricsOrder([]);
    // Reset change flags
    setHasDescriptionChanged(false);
    setHasDisplayNameChanged(false);
    setHasStateChanged(false);
    setShowAllObservables(false);
  }

  useEffect(() => {
    // Reset selections when environment changes
    resetAllStates();

    // Re-fetch workflows if we have customers selected
    if (workflowCustomerId) {
      fetchWorkflows(workflowCustomerId, false);
    }
    if (metricsCustomerId) {
      fetchWorkflows(metricsCustomerId, true);
    }
  }, [env]);

  const fetchCustomers = useCallback(async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/customers`, {
        headers: {
          Authorization: `Bearer ${googleAuthToken}`,
        },
      });
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  }, [googleAuthToken]);

  useEffect(() => {
    fetchCustomers();
  }, [env, fetchCustomers]);

  const fetchWorkflows = async (customerId, forMetrics = false) => {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/customer-workflows/${customerId}`,
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      const workflowObjects = response.data.map((workflow) => ({
        id: workflow.id,
        name: workflow.name,
        description: workflow.description,
        display_name: workflow.display_name,
        state: workflow.state,
      }));

      // Set the appropriate workflows state based on usage
      if (forMetrics) {
        setMetricWorkflows(workflowObjects);
      } else {
        setWorkflowDetailsWorkflows(workflowObjects);
      }
      return workflowObjects;
    } catch (error) {
      console.error("Error fetching workflows:", error);
      return [];
    }
  };

  const handleWorkflowCustomerChange = (e) => {
    const customerId = e.target.value;
    setWorkflowCustomerId(customerId);
    setSelectedWorkflow("");
    setWorkflowDescription("");
    setWorkflowDisplayName("");

    if (customerId) {
      fetchWorkflows(customerId, false);
    }
  };

  const handleMetricsCustomerChange = (e) => {
    const customerId = e.target.value;
    setMetricsCustomerId(customerId);
    setSelectedMetricWorkflow("");
    setExistingMetrics([]);

    if (customerId) {
      fetchWorkflows(customerId, true);
    }
  };

  const handleWorkflowChange = (e) => {
    const workflowName = e.target.value;
    setSelectedWorkflow(workflowName);
    const workflow = workflowDetailsWorkflows.find(
      (w) => w.name === workflowName
    );
    setWorkflowDescription(workflow?.description || "");
    setWorkflowDisplayName(workflow?.display_name || "");
    setWorkflowState(workflow?.state || "");
    setHasDescriptionChanged(false);
    setHasDisplayNameChanged(false);
    setHasStateChanged(false);
    setUpdateResult("");
    setMigrateWorkflowResult("");

    if (workflow) {
      if (env === "dev") {
        checkWorkflowSyncStatus(workflow.id);
      } else {
        setWorkflowSyncStatus({ loading: false, synced: null });
      }
    } else {
      setWorkflowSyncStatus({ loading: false, synced: null });
    }
  };

  const handleDescriptionChange = (e) => {
    setWorkflowDescription(e.target.value);
    setHasDescriptionChanged(true);
  };

  const handleDisplayNameChange = (e) => {
    setWorkflowDisplayName(e.target.value);
    setHasDisplayNameChanged(true);
  };

  const handleStateChange = (e) => {
    setWorkflowState(e.target.value);
    setHasStateChanged(true);
  };

  const updateWorkflowDetails = async (e) => {
    e.preventDefault();

    // Prevent direct updates in production
    if (env !== "dev") {
      setUpdateResult(
        "Workflow details can only be updated in the dev environment. Please use the migration tool to update production."
      );
      return;
    }

    try {
      const workflow = workflowDetailsWorkflows.find(
        (w) => w.name === selectedWorkflow
      );
      if (!workflow) return;

      const updates = [];

      if (hasDescriptionChanged) {
        updates.push(
          axios.put(
            `${config.apiBaseUrl}/workflow-description/${workflow.id}`,
            { description: workflowDescription },
            { headers: { Authorization: `Bearer ${googleAuthToken}` } }
          )
        );
      }

      if (hasDisplayNameChanged) {
        updates.push(
          axios.put(
            `${config.apiBaseUrl}/workflow-display-name/${workflow.id}`,
            { display_name: workflowDisplayName },
            { headers: { Authorization: `Bearer ${googleAuthToken}` } }
          )
        );
      }

      if (hasStateChanged) {
        updates.push(
          axios.put(
            `${config.apiBaseUrl}/workflow-state/${workflow.id}`,
            { state: workflowState },
            { headers: { Authorization: `Bearer ${googleAuthToken}` } }
          )
        );
      }

      if (updates.length > 0) {
        await Promise.all(updates);
        setUpdateResult("Workflow details updated successfully");

        // Refresh workflows
        if (workflowCustomerId) {
          const updatedWorkflows = await fetchWorkflows(
            workflowCustomerId,
            false
          );
          const updatedWorkflow = updatedWorkflows.find(
            (w) => w.name === selectedWorkflow
          );
          setWorkflowDescription(updatedWorkflow?.description || "");
          setWorkflowDisplayName(updatedWorkflow?.display_name || "");
          setWorkflowState(updatedWorkflow?.state || "");

          if (updatedWorkflow) {
            await checkWorkflowSyncStatus(updatedWorkflow.id);
          }
        }

        setHasDescriptionChanged(false);
        setHasDisplayNameChanged(false);
        setHasStateChanged(false);
      }
    } catch (error) {
      setUpdateResult(`Error: ${error.response?.data?.error || error.message}`);
    }
  };

  const handleMetricsWorkflowChange = (e) => {
    const workflowName = e.target.value;
    setSelectedMetricWorkflow(workflowName);
    setSelectedObservable("");
    setSelectedAction("");
    // Reset metrics-related messages
    setmigrateMetricResult("");
    setSaveOrderResult("");

    const workflow = metricWorkflows.find((w) => w.name === workflowName);

    if (workflow) {
      fetchWorkflowMetrics(workflow.id);
      if (env === "dev") {
        fetchObservables(workflow.id);
        fetchActions(workflow.id);
        checkMetricsSyncStatus(workflow.id);
      }
    } else {
      setExistingMetrics([]);
      setObservables([]);
      setActions([]);
      setMetricsSyncStatus({ loading: false, synced: null });
    }
  };

  const handleMetricSubmit = async (e) => {
    e.preventDefault();
    if (!selectedMetricWorkflow) return;

    // Prevent metric creation in prod
    if (env !== "dev") {
      setMetricResult(
        "Metrics can only be created in the dev environment. To add metrics to production, please create them in dev and use the migration tool."
      );
      return;
    }

    const workflow = metricWorkflows.find(
      (w) => w.name === selectedMetricWorkflow
    );
    if (!workflow) return;

    // Format the OS metric field based on type
    let formattedOsMetric = null;
    if (isOsMetric) {
      switch (osMetricType) {
        case "action":
          formattedOsMetric = `a_${selectedAction}`;
          break;
        case "observable":
          formattedOsMetric = `o_${selectedObservable}`;
          break;
        case "instance":
          formattedOsMetric = "num_instances";
          break;
        default:
          formattedOsMetric = null;
          break;
      }
    }

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/metrics`,
        {
          workflow_id: workflow.id,
          metric_name: metricName,
          metric_definition: metricDefinition,
          os_metric: formattedOsMetric,
          metric_type: metricType,
          metric_aggregation: metricAggregation,
          time_interval: timeInterval,
          metric_format: metricFormat,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setMetricResult(
        `Metric added successfully. Metric ID: ${response.data.id}`
      );

      // Refresh the metrics list
      await fetchWorkflowMetrics(workflow.id);
      // Add sync status check
      await checkMetricsSyncStatus(workflow.id);

      // Reset form
      setMetricName("");
      setMetricDefinition("");
      setIsOsMetric(false);
      setOsMetricType("");
      setMetricType("");
      setMetricAggregation("");
      setTimeInterval("");
      setMetricFormat("");
    } catch (error) {
      setMetricResult(`Error: ${error.response?.data?.error || error.message}`);
    }
  };

  const fetchWorkflowMetrics = async (workflowId) => {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/metrics/${workflowId}`,
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      // Ensure metrics have a position attribute
      const metricsWithPosition = response.data.map((metric, index) => ({
        ...metric,
        position: metric.position || index,
      }));

      // Sort metrics by position if available
      metricsWithPosition.sort((a, b) => a.position - b.position);

      // Store the original order for comparison
      setOriginalMetricsOrder(metricsWithPosition.map((metric) => metric.id));

      setExistingMetrics(metricsWithPosition);
      setMetricsOrderChanged(false);
    } catch (error) {
      console.error("Error fetching workflow metrics:", error);
    }
  };

  const fetchObservables = async (workflowId) => {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/workflow-observables/${workflowId}`,
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      // Store the full response with timestamps (assuming backend is updated similarly to actions)
      setObservables(response.data);
    } catch (error) {
      console.error("Error fetching observables:", error);
      setObservables([]);
    }
  };

  const fetchActions = async (workflowId) => {
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/workflow-actions/${workflowId}`,
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );

      // Store the full response with timestamps
      setActions(response.data);
    } catch (error) {
      console.error("Error fetching actions:", error);
      setActions([]);
    }
  };

  const copyToClipboard = async (text, e) => {
    if (e) e.preventDefault();
    try {
      await navigator.clipboard.writeText(text);
      // Optional: You could add a toast/notification here
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  const formatId = (id) => {
    return `...${id.slice(-4)}`;
  };

  const handleDeleteMetric = async (e) => {
    e.preventDefault();
    if (
      env === "prod" &&
      !window.confirm(
        "Are you sure you want to delete this metric in the production environment?"
      )
    ) {
      return;
    }

    try {
      await axios.delete(`${config.apiBaseUrl}/metrics/${metricIdToDelete}`, {
        headers: {
          Authorization: `Bearer ${googleAuthToken}`,
        },
      });
      setDeleteMetricResult("Metric deleted successfully");
      setMetricIdToDelete("");

      // Refresh metrics list if a workflow is selected
      if (selectedMetricWorkflow) {
        const workflow = metricWorkflows.find(
          (w) => w.name === selectedMetricWorkflow
        );
        if (workflow) {
          await fetchWorkflowMetrics(workflow.id);
          // Add sync status check
          await checkMetricsSyncStatus(workflow.id);
        }
      }
    } catch (error) {
      setDeleteMetricResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  };

  const handleOsMetricChange = (e) => {
    setIsOsMetric(e.target.checked);
    if (!e.target.checked) {
      // Reset related fields when OS metric is unchecked
      setOsMetricType("");
      setSelectedObservable("");
      setSelectedAction("");
    }
  };

  const handleEditMetric = async (e) => {
    e.preventDefault();
    if (
      env === "prod" &&
      !window.confirm(
        "Are you sure you want to edit this metric in the production environment?"
      )
    ) {
      return;
    }

    try {
      await axios.put(
        `${config.apiBaseUrl}/metrics/${editingMetric.id}`,
        {
          metric_name: editingMetric.metric_name,
          metric_definition: editingMetric.metric_definition,
          metric_type: editingMetric.metric_type,
          metric_aggregation: editingMetric.metric_aggregation,
          time_interval: editingMetric.time_interval,
          metric_format: editingMetric.metric_format,
          os_metric: editingMetric.os_metric,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );

      setEditMetricResult("Metric updated successfully");

      // Refresh the metrics list while maintaining the current selections
      const workflow = metricWorkflows.find(
        (w) => w.name === selectedMetricWorkflow
      );
      if (workflow) {
        await fetchWorkflowMetrics(workflow.id);
        // Add sync status check
        await checkMetricsSyncStatus(workflow.id);
      }

      // Wait a brief moment before closing the form so the user can see the success message
      setTimeout(() => {
        setEditingMetric(null);
        setEditMetricResult("");
      }, 1500);
    } catch (error) {
      setEditMetricResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  };

  // Update check function to handle loading state
  const checkMetricsSyncStatus = async (workflowId) => {
    setMetricsSyncStatus({ loading: true, synced: null });
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/metrics-sync-status/${workflowId}`,
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setMetricsSyncStatus({ loading: false, synced: response.data.synced });
    } catch (error) {
      console.error("Error checking metrics sync status:", error);
      setMetricsSyncStatus({ loading: false, synced: null });
    }
  };

  // Update handleMigrateAllMetrics to refresh sync status after migration
  const handleMigrateAllMetrics = async () => {
    if (env !== "dev") {
      setmigrateMetricResult(
        "Metrics can only be migrated when in the development environment"
      );
      return;
    }

    if (!selectedMetricWorkflow) {
      setmigrateMetricResult("Please select a workflow first");
      return;
    }

    const workflow = metricWorkflows.find(
      (w) => w.name === selectedMetricWorkflow
    );
    if (!workflow) return;

    if (
      !window.confirm(
        "Are you sure you want to migrate all metrics to production?"
      )
    ) {
      return;
    }

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/migrate-all-metrics/${workflow.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );

      // Format the migration result message based on what succeeded
      const { results } = response.data;
      let resultMessage;

      if (results.staging.success && results.prod.success) {
        resultMessage =
          "Successfully migrated metrics to staging and production";
      } else if (results.staging.success) {
        resultMessage =
          "Successfully migrated metrics to staging only. Production migration failed: " +
          results.prod.details;
      } else if (results.prod.success) {
        resultMessage =
          "Successfully migrated metrics to production only. Staging migration failed: " +
          results.staging.details;
      } else {
        resultMessage =
          "Metrics migration failed:\n" +
          "Staging: " +
          results.staging.details +
          "\n" +
          "Production: " +
          results.prod.details;
      }

      setmigrateMetricResult(resultMessage);

      // Refresh the metrics list and sync status only if at least one migration succeeded
      if (results.staging.success || results.prod.success) {
        await fetchWorkflowMetrics(workflow.id);
        await checkMetricsSyncStatus(workflow.id);
      }
    } catch (error) {
      setmigrateMetricResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  };

  // Add function to check workflow sync status
  const checkWorkflowSyncStatus = async (workflowId) => {
    setWorkflowSyncStatus({ loading: true, synced: null });
    try {
      const response = await axios.get(
        `${config.apiBaseUrl}/workflow-sync-status/${workflowId}`,
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setWorkflowSyncStatus({ loading: false, synced: response.data.synced });
    } catch (error) {
      console.error("Error checking workflow sync status:", error);
      setWorkflowSyncStatus({ loading: false, synced: null });
    }
  };

  // Add function to migrate workflow details to production
  const handleMigrateWorkflow = async () => {
    if (env !== "dev") {
      setMigrateWorkflowResult(
        "Workflow details can only be migrated when in the development environment"
      );
      return;
    }

    if (!selectedWorkflow) {
      setMigrateWorkflowResult("Please select a workflow first");
      return;
    }

    const workflow = workflowDetailsWorkflows.find(
      (w) => w.name === selectedWorkflow
    );
    if (!workflow) return;

    if (
      !window.confirm(
        "Are you sure you want to migrate workflow details to production?"
      )
    ) {
      return;
    }

    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/migrate-workflow/${workflow.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );

      // Format the migration result message based on what succeeded
      const { results } = response.data;
      let resultMessage = `Migration results for ${response.data.workflow_name}:\n`;

      if (results.staging.success && results.prod.success) {
        resultMessage = "Successfully migrated to staging and production";
      } else if (results.staging.success) {
        resultMessage =
          "Successfully migrated to staging. Production migration failed likely due to the workflow not having run yet there.  ";
      } else if (results.prod.success) {
        resultMessage =
          "Successfully migrated to production. Staging migration failed likely due to the workflow not having run yet there.  ";
      } else {
        resultMessage =
          "Migration failed likely due to the workflow not having run yet in staging or production.";
      }

      setMigrateWorkflowResult(resultMessage);

      // Refresh workflows and sync status only if at least one migration succeeded
      if (results.staging.success || results.prod.success) {
        if (workflowCustomerId) {
          await fetchWorkflows(workflowCustomerId, false);
          await checkWorkflowSyncStatus(workflow.id);
        }
      }
    } catch (error) {
      setMigrateWorkflowResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  };

  // Add a handler function for environment switching that works bidirectionally
  const handleEnvironmentSwitch = (newEnv) => {
    switchEnvironment(newEnv);
  };

  // Set up sensors for drag-and-drop
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5, // Minimum drag distance in pixels
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Update handleDragEnd to automatically save order after metrics are moved
  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setExistingMetrics((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        // Move the item in the array
        const newItems = arrayMove(items, oldIndex, newIndex);

        // Update positions based on new order
        const updatedItems = newItems.map((item, index) => ({
          ...item,
          position: index,
        }));

        // Compare current order with original order
        const currentOrder = updatedItems.map((item) => item.id);
        const orderChanged = !arraysEqual(currentOrder, originalMetricsOrder);

        // Only trigger save if order actually changed
        if (orderChanged) {
          // Use setTimeout to ensure state update completes first
          setTimeout(() => saveMetricsOrder(updatedItems), 0);
        }

        return updatedItems;
      });
    }
  };

  // Modified to accept metrics array parameter for immediate saving
  const saveMetricsOrder = async (metricsToSave = null) => {
    if (!selectedMetricWorkflow) return;

    const workflow = metricWorkflows.find(
      (w) => w.name === selectedMetricWorkflow
    );
    if (!workflow) return;

    setSaveOrderResult("");
    setMetricsOrderChanged(false);

    try {
      // Use provided metrics if available, otherwise use state
      const metrics = metricsToSave || existingMetrics;
      const metricsOrder = metrics.map((metric) => ({
        id: metric.id,
        position: metric.position,
      }));

      await axios.put(
        `${config.apiBaseUrl}/metrics-order/${workflow.id}`,
        { metrics: metricsOrder },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );

      setSaveOrderResult("Metrics order saved successfully");

      // Set the new order as the original order
      setOriginalMetricsOrder(metrics.map((metric) => metric.id));

      // Clear the message after a delay
      setTimeout(() => {
        setSaveOrderResult("");
      }, 3000);

      // Add sync status check
      if (env === "dev") {
        await checkMetricsSyncStatus(workflow.id);
      }
    } catch (error) {
      setSaveOrderResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  };

  // Helper function to compare arrays
  const arraysEqual = (a, b) => {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };

  return (
    <div className="AdminPage">
      <h1>Workflow Config</h1>
      <EnvSwitcher onEnvironmentSwitch={handleEnvironmentSwitch} />
      <br />
      <br />
      <div className="admin-columns">
        <div className="admin-column">
          <div className="container">
            <h2>Edit Workflow Details</h2>
            <div>
              <label>
                <select
                  value={workflowCustomerId}
                  onChange={handleWorkflowCustomerChange}
                >
                  <option value="">Select a customer</option>
                  {customers.map((customer) => (
                    <option key={customer.id} value={customer.id}>
                      {customer.name}
                    </option>
                  ))}
                </select>
              </label>
              <br />
              <label>
                <select
                  value={selectedWorkflow}
                  onChange={handleWorkflowChange}
                  disabled={!workflowCustomerId}
                >
                  <option value="">Select Workflow</option>
                  {workflowDetailsWorkflows.map((workflow) => (
                    <option key={workflow.id} value={workflow.name}>
                      {workflow.name} ({workflow.state || "unknown"})
                    </option>
                  ))}
                </select>
              </label>
              <br />
              <form onSubmit={updateWorkflowDetails}>
                <textarea
                  placeholder="Workflow Description"
                  value={workflowDescription}
                  onChange={handleDescriptionChange}
                  disabled={!selectedWorkflow || env !== "dev"}
                  rows={4}
                  style={{ resize: "vertical" }}
                />
                <br />
                <textarea
                  placeholder="Workflow Display Name"
                  value={workflowDisplayName}
                  onChange={handleDisplayNameChange}
                  disabled={!selectedWorkflow || env !== "dev"}
                  rows={4}
                  style={{ resize: "vertical" }}
                />
                <br />
                <select
                  value={workflowState}
                  onChange={handleStateChange}
                  disabled={!selectedWorkflow || env !== "dev"}
                >
                  <option value="">Select State</option>
                  {WORKFLOW_STATES.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
                {env === "dev" && (
                  <>
                    <button
                      type="submit"
                      disabled={
                        !selectedWorkflow ||
                        (!hasDescriptionChanged &&
                          !hasDisplayNameChanged &&
                          !hasStateChanged)
                      }
                    >
                      Update Workflow Details
                    </button>
                    {updateResult && (
                      <div className="result">{updateResult}</div>
                    )}
                    {selectedWorkflow && (
                      <div style={{ marginTop: "20px" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            type="button"
                            onClick={handleMigrateWorkflow}
                            disabled={workflowSyncStatus.synced}
                            title={
                              workflowSyncStatus.synced
                                ? "Workflow details are already synced with staging and production"
                                : ""
                            }
                          >
                            Migrate to Staging & Prod
                          </button>
                          <div
                            title={
                              workflowSyncStatus.loading
                                ? "Checking sync status..."
                                : workflowSyncStatus.synced
                                  ? "Workflow details are synced with staging and production"
                                  : "Workflow details are not synced with staging and production"
                            }
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            {workflowSyncStatus.loading ? (
                              <BiRefresh
                                className="spin"
                                style={{
                                  color: "#888",
                                  fontSize: "1.2em",
                                }}
                              />
                            ) : workflowSyncStatus.synced ? (
                              <FaCheck
                                style={{
                                  color: "green",
                                  fontSize: "1.2em",
                                }}
                              />
                            ) : (
                              <FaTimes
                                style={{
                                  color: "red",
                                  fontSize: "1.2em",
                                }}
                              />
                            )}
                          </div>
                        </div>
                        {migrateWorkflowResult && (
                          <div className="result" style={{ marginTop: "10px" }}>
                            {migrateWorkflowResult}
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </form>
            </div>
          </div>
        </div>
        <div className="admin-column">
          <div className="container">
            <h2>Workflow Metrics</h2>
            <div>
              <label>
                <select
                  value={metricsCustomerId}
                  onChange={handleMetricsCustomerChange}
                  required
                >
                  <option value="">Select a customer</option>
                  {customers.map((customer) => (
                    <option key={customer.id} value={customer.id}>
                      {customer.name}
                    </option>
                  ))}
                </select>
              </label>
              <br />
              <label>
                <select
                  value={selectedMetricWorkflow}
                  onChange={handleMetricsWorkflowChange}
                  disabled={!metricsCustomerId}
                  required
                >
                  <option value="">Select Workflow</option>
                  {metricWorkflows.map((workflow) => (
                    <option key={workflow.id} value={workflow.name}>
                      {workflow.name}
                    </option>
                  ))}
                </select>
              </label>
              {existingMetrics.length > 0 && (
                <div
                  className="existing-metrics"
                  style={{ marginTop: "10px", marginBottom: "20px" }}
                >
                  <h3>Existing Metrics</h3>
                  {env === "dev" && (
                    <div style={{ marginBottom: "10px" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          onClick={handleMigrateAllMetrics}
                          disabled={metricsSyncStatus.synced}
                          title={
                            metricsSyncStatus.synced
                              ? "Metrics are already synced with staging and production"
                              : ""
                          }
                        >
                          Migrate to Staging & Prod
                        </button>
                        <div
                          title={
                            metricsSyncStatus.loading
                              ? "Checking sync status..."
                              : metricsSyncStatus.synced
                                ? "Metrics are synced with staging and production"
                                : "Metrics are not synced with staging and production"
                          }
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          {metricsSyncStatus.loading ? (
                            <BiRefresh
                              className="spin"
                              style={{
                                color: "#888",
                                fontSize: "1.2em",
                              }}
                            />
                          ) : metricsSyncStatus.synced ? (
                            <FaCheck
                              style={{
                                color: "green",
                                fontSize: "1.2em",
                              }}
                            />
                          ) : (
                            <FaTimes
                              style={{
                                color: "red",
                                fontSize: "1.2em",
                              }}
                            />
                          )}
                        </div>
                      </div>
                      {migrateMetricResult && (
                        <div className="result" style={{ marginTop: "10px" }}>
                          {migrateMetricResult}
                        </div>
                      )}
                    </div>
                  )}
                  <div>
                    <DndContext
                      sensors={sensors}
                      collisionDetection={closestCenter}
                      onDragEnd={handleDragEnd}
                    >
                      <table style={{ width: "100%" }}>
                        <thead>
                          <tr>
                            {env === "dev" && (
                              <th style={{ width: "40px" }}>Order</th>
                            )}
                            <th>ID</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Type</th>
                            <th>OS Metric</th>
                            <th>Aggregation</th>
                            <th>Time Interval</th>
                            {env === "dev" && <th>Actions</th>}
                          </tr>
                        </thead>
                        <tbody>
                          <SortableContext
                            items={existingMetrics.map((metric) => metric.id)}
                            strategy={verticalListSortingStrategy}
                          >
                            {existingMetrics.map((metric) => (
                              <SortableMetricRow
                                key={metric.id}
                                metric={metric}
                                formatId={formatId}
                                copyToClipboard={copyToClipboard}
                                env={env}
                                setEditingMetric={setEditingMetric}
                              />
                            ))}
                          </SortableContext>
                        </tbody>
                      </table>
                    </DndContext>

                    {/* Moved saveOrderResult message below the table */}
                    {saveOrderResult && (
                      <div
                        className="result"
                        style={{
                          marginTop: "15px",
                          textAlign: "center",
                          padding: "10px",
                          backgroundColor: saveOrderResult.includes("Error")
                            ? "#ffeded"
                            : "#edfff0",
                          borderRadius: "4px",
                        }}
                      >
                        {saveOrderResult}
                      </div>
                    )}
                  </div>

                  {/* Edit Metric Form */}
                  {editingMetric && (
                    <div
                      className="edit-metric-form"
                      style={{ marginTop: "20px" }}
                    >
                      <h3>Edit Metric</h3>
                      <form onSubmit={handleEditMetric}>
                        <input
                          type="text"
                          placeholder="Metric Name"
                          value={editingMetric.metric_name}
                          onChange={(e) =>
                            setEditingMetric({
                              ...editingMetric,
                              metric_name: e.target.value,
                            })
                          }
                          required
                        />
                        <br />
                        <textarea
                          placeholder="Metric Definition"
                          value={editingMetric.metric_definition}
                          onChange={(e) =>
                            setEditingMetric({
                              ...editingMetric,
                              metric_definition: e.target.value,
                            })
                          }
                          required
                          rows={4}
                          style={{ resize: "vertical" }}
                        />
                        <br />
                        {/* Display OS metric info if it exists, but don't allow editing */}
                        {editingMetric.os_metric && (
                          <div style={{ marginBottom: "10px" }}>
                            <strong>OS Metric:</strong>{" "}
                            {editingMetric.os_metric}
                            <br />
                          </div>
                        )}
                        <select
                          value={editingMetric.metric_type}
                          onChange={(e) =>
                            setEditingMetric({
                              ...editingMetric,
                              metric_type: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="">Display Type</option>
                          {METRIC_TYPES.map((type) => (
                            <option key={type} value={type}>
                              {type.replace("_", " ")}
                            </option>
                          ))}
                        </select>
                        <br />
                        <select
                          value={editingMetric.metric_aggregation}
                          onChange={(e) =>
                            setEditingMetric({
                              ...editingMetric,
                              metric_aggregation: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="">Aggregation Method</option>
                          {METRIC_AGGREGATIONS.map((agg) => (
                            <option key={agg} value={agg}>
                              {agg}
                            </option>
                          ))}
                        </select>
                        <br />
                        <select
                          value={editingMetric.time_interval}
                          onChange={(e) =>
                            setEditingMetric({
                              ...editingMetric,
                              time_interval: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="">Time Interval</option>
                          {TIME_INTERVALS.map((interval) => (
                            <option key={interval} value={interval}>
                              {interval}
                            </option>
                          ))}
                        </select>
                        <br />
                        <select
                          value={editingMetric.metric_format}
                          onChange={(e) =>
                            setEditingMetric({
                              ...editingMetric,
                              metric_format: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="">Format</option>
                          {Object.entries(METRIC_FORMATS).map(
                            ([format, description]) => (
                              <option key={format} value={format}>
                                {format} {description}
                              </option>
                            )
                          )}
                        </select>
                        <br />
                        <div
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            gap: "10px",
                          }}
                        >
                          <button
                            type="submit"
                            style={{ flex: 1, padding: "10px 0" }}
                          >
                            Save Changes
                          </button>
                          <button
                            type="button"
                            onClick={() => setEditingMetric(null)}
                            style={{ flex: 1, padding: "10px 0" }}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  )}

                  {/* Show edit result message outside the edit form */}
                  {editMetricResult && (
                    <div className="result" style={{ marginTop: "10px" }}>
                      {editMetricResult}
                    </div>
                  )}
                </div>
              )}
              {/* Only show Add Metric form and Delete Metric in dev environment */}
              {env === "dev" && !editingMetric && (
                <>
                  <form
                    onSubmit={handleMetricSubmit}
                    style={{ marginTop: "20px" }}
                  >
                    <input
                      type="text"
                      placeholder="Metric Name"
                      value={metricName}
                      onChange={(e) => setMetricName(e.target.value)}
                      required
                      disabled={!selectedMetricWorkflow}
                    />
                    <br />
                    <textarea
                      placeholder="Metric Definition"
                      value={metricDefinition}
                      onChange={(e) => setMetricDefinition(e.target.value)}
                      required
                      rows={4}
                      style={{ resize: "vertical" }}
                      disabled={!selectedMetricWorkflow}
                    />
                    <br />
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        checked={isOsMetric}
                        onChange={handleOsMetricChange}
                        disabled={!selectedMetricWorkflow}
                      />
                      OS Metric
                    </label>
                    <br />
                    {isOsMetric && (
                      <select
                        value={osMetricType}
                        onChange={(e) => setOsMetricType(e.target.value)}
                        required
                        disabled={!selectedMetricWorkflow}
                      >
                        <option value="">OS Metric Type</option>
                        {OS_METRIC_TYPES.map((type) => (
                          <option key={type} value={type}>
                            {type.replace("_", " ")}
                          </option>
                        ))}
                      </select>
                    )}
                    {isOsMetric && osMetricType === "observable" && (
                      <>
                        <br />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <select
                            value={selectedObservable}
                            onChange={(e) =>
                              setSelectedObservable(e.target.value)
                            }
                            required
                            disabled={!selectedMetricWorkflow}
                            style={{ flex: 1 }}
                          >
                            <option value="">Select Observable</option>
                            {observables
                              .filter(
                                (observable) =>
                                  showAllObservables ||
                                  new Date(observable.lastUsedAt) >=
                                    new Date(
                                      Date.now() - 14 * 24 * 60 * 60 * 1000
                                    )
                              )
                              .map((observable) => (
                                <option
                                  key={observable.name}
                                  value={observable.name}
                                >
                                  {observable.name}
                                  {new Date(observable.lastUsedAt) <
                                    new Date(
                                      Date.now() - 14 * 24 * 60 * 60 * 1000
                                    ) && " (over 14 days old)"}
                                </option>
                              ))}
                          </select>
                          <label
                            className="checkbox-label"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <input
                              type="checkbox"
                              checked={showAllObservables}
                              onChange={(e) =>
                                setShowAllObservables(e.target.checked)
                              }
                              disabled={!selectedMetricWorkflow}
                            />
                            Show All Observables
                          </label>
                        </div>
                      </>
                    )}
                    {isOsMetric && osMetricType === "action" && (
                      <>
                        <br />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <select
                            value={selectedAction}
                            onChange={(e) => setSelectedAction(e.target.value)}
                            required
                            disabled={!selectedMetricWorkflow}
                            style={{ flex: 1 }}
                          >
                            <option value="">Select Action</option>
                            {actions
                              .filter(
                                (action) =>
                                  showAllActions ||
                                  new Date(action.lastUsedAt) >=
                                    new Date(
                                      Date.now() - 14 * 24 * 60 * 60 * 1000
                                    )
                              )
                              .map((action) => (
                                <option key={action.name} value={action.name}>
                                  {action.name}
                                  {new Date(action.lastUsedAt) <
                                    new Date(
                                      Date.now() - 14 * 24 * 60 * 60 * 1000
                                    ) && " (over 14 days old)"}
                                </option>
                              ))}
                          </select>
                          <label
                            className="checkbox-label"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <input
                              type="checkbox"
                              checked={showAllActions}
                              onChange={(e) =>
                                setShowAllActions(e.target.checked)
                              }
                              disabled={!selectedMetricWorkflow}
                            />
                            Show All Actions
                          </label>
                        </div>
                      </>
                    )}
                    <br />
                    <select
                      value={metricType}
                      onChange={(e) => setMetricType(e.target.value)}
                      required
                      disabled={!selectedMetricWorkflow}
                    >
                      <option value="">Display Type</option>
                      {METRIC_TYPES.map((type) => (
                        <option key={type} value={type}>
                          {type.replace("_", " ")}
                        </option>
                      ))}
                    </select>
                    <br />
                    <select
                      value={metricAggregation}
                      onChange={(e) => setMetricAggregation(e.target.value)}
                      required
                      disabled={!selectedMetricWorkflow}
                    >
                      <option value="">Aggregation Method</option>
                      {METRIC_AGGREGATIONS.map((agg) => (
                        <option key={agg} value={agg}>
                          {agg}
                        </option>
                      ))}
                    </select>
                    <br />
                    <select
                      value={timeInterval}
                      onChange={(e) => setTimeInterval(e.target.value)}
                      required
                      disabled={!selectedMetricWorkflow}
                    >
                      <option value="">Time Interval</option>
                      {TIME_INTERVALS.map((interval) => (
                        <option key={interval} value={interval}>
                          {interval}
                        </option>
                      ))}
                    </select>
                    <br />
                    <select
                      value={metricFormat}
                      onChange={(e) => setMetricFormat(e.target.value)}
                      required
                      disabled={!selectedMetricWorkflow}
                    >
                      <option value="">Format</option>
                      {Object.entries(METRIC_FORMATS).map(
                        ([format, description]) => (
                          <option key={format} value={format}>
                            {format} {description}
                          </option>
                        )
                      )}
                    </select>
                    <br />
                    <button type="submit" disabled={!selectedMetricWorkflow}>
                      Add Metric
                    </button>
                  </form>
                  {metricResult && <div className="result">{metricResult}</div>}
                </>
              )}
            </div>
          </div>
          {env === "dev" && (
            <div className="container">
              <h2>Delete Metric</h2>
              <form onSubmit={handleDeleteMetric}>
                <input
                  type="text"
                  placeholder="Metric ID"
                  value={metricIdToDelete}
                  onChange={(e) => setMetricIdToDelete(e.target.value)}
                  required
                />
                <button type="submit" disabled={!metricIdToDelete}>
                  Delete Metric
                </button>
              </form>
              {deleteMetricResult && (
                <div className="result">{deleteMetricResult}</div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default WorkflowConfig;

<style>
  {`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .spin {
    animation: spin 1s linear infinite;
  }
  
  /* Improve styles for draggable rows */
  tr.dragging {
    background-color: #f0f8ff !important;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    opacity: 0.9;
  }
  
  /* Make sure the table has proper layout */
  table {
    border-collapse: collapse;
    width: 100%;
  }
  
  tbody {
    width: 100%;
  }
`}
</style>;
