import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import config from "../config";
import "../styles/LiveDashboard.css";
import { EnvironmentContext } from "../contexts/EnvironmentContext";

function LiveDashboard({ googleAuthToken, onLogout, privacyMode }) {
  const [stats, setStats] = useState({
    totalWorkflows: 0,
    activeWorkflows: 0,
    totalInstances: 0,
    totalTasks: 0,
  });
  const [recentActivity, setRecentActivity] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  // Update activity pulse state to track active workflow instances
  const [activityPulse, setActivityPulse] = useState({
    timestamp: null,
    totalTasks: [],
    shouldPulseGlobal: false,
  });

  // Add new ref for workflow name elements
  const workflowRefs = React.useRef({});

  // Force production environment for live dashboard
  const { switchEnvironment } = useContext(EnvironmentContext);

  useEffect(() => {
    // Ensure we're always in production environment
    switchEnvironment("prod");
  }, [switchEnvironment]);

  // Update effect to use the new enhanced activity pulse endpoint
  useEffect(() => {
    let lastSeenTimestamp = null;
    // Track which workflow instances we've already pulsed
    const pulsedInstances = new Set();
    // Add buffer to batch animations
    let animationBuffer = [];
    let bufferTimeout = null;

    const checkActivityPulse = async () => {
      try {
        const headers = googleAuthToken
          ? { Authorization: `Bearer ${googleAuthToken}` }
          : {};

        const response = await axios.get(
          `${config.apiBaseUrl}/display/recent-activity-pulse`,
          { headers }
        );

        const { active_workflow_ids, timestamp, count } = response.data;

        // Only process if timestamp is new and there are active workflows
        if (count > 0 && timestamp !== lastSeenTimestamp) {
          lastSeenTimestamp = timestamp;

          // Only pulse workflows we haven't pulsed recently
          const newActivations = active_workflow_ids.filter(
            (id) => !pulsedInstances.has(id)
          );

          if (newActivations.length > 0) {
            // Clear existing buffer timeout
            if (bufferTimeout) {
              clearTimeout(bufferTimeout);
            }

            // Add new IDs to buffer
            animationBuffer = [...animationBuffer, ...newActivations];

            // Set timeout to process buffer
            bufferTimeout = setTimeout(() => {
              // Add all buffered IDs to pulsed set
              animationBuffer.forEach((id) => pulsedInstances.add(id));

              setActivityPulse({
                timestamp,
                totalTasks: animationBuffer,
                shouldPulseGlobal: false,
              });

              // Clear animations after duration
              setTimeout(() => {
                animationBuffer.forEach((id) => pulsedInstances.delete(id));
                setActivityPulse((prev) => ({
                  ...prev,
                  totalTasks: [],
                }));
                animationBuffer = [];
              }, 1500);
            }, 100); // Small delay to batch nearby activations
          }
        }
      } catch (err) {
        console.error("Error fetching activity pulse:", err);
      }
    };

    checkActivityPulse();
    const pulseInterval = setInterval(checkActivityPulse, 1000);
    return () => {
      clearInterval(pulseInterval);
      if (bufferTimeout) {
        clearTimeout(bufferTimeout);
      }
    };
  }, [googleAuthToken]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const headers = googleAuthToken
          ? {
              Authorization: `Bearer ${googleAuthToken}`,
            }
          : {};

        const response = await axios.get(
          `${config.apiBaseUrl}/display/dashboard-stats`,
          { headers }
        );

        setStats((prevStats) => {
          const newStats = response.data.stats;
          return {
            ...newStats,
            _changed: {
              activeWorkflows:
                prevStats.activeWorkflows !== newStats.activeWorkflows,
              totalInstances:
                prevStats.totalInstances !== newStats.totalInstances,
              totalTasks: prevStats.totalTasks !== newStats.totalTasks,
              totalAgents: prevStats.totalAgents !== newStats.totalAgents,
            },
          };
        });

        // Force removal of pulse class after animation
        setTimeout(() => {
          setStats((prevStats) => ({
            ...prevStats,
            _changed: {
              activeWorkflows: false,
              totalInstances: false,
              totalTasks: false,
            },
          }));
        }, 1000);

        setRecentActivity((prevActivity) => {
          const newActivity = response.data.recentActivity;
          return newActivity.map((activity) => ({
            ...activity,
            isNew: !prevActivity.some(
              (prev) =>
                prev.timestamp === activity.timestamp &&
                prev.customer_name === activity.customer_name &&
                prev.workflow_name === activity.workflow_name &&
                prev.workflow_instance_id === activity.workflow_instance_id
            ),
          }));
        });

        setIsLoading(false);
      } catch (err) {
        setError("Failed to load dashboard data");
        setIsLoading(false);

        if (err.response?.status === 401 && googleAuthToken) {
          console.log("Unauthorized access. Logging out...");
          onLogout();
        }
      }
    };

    fetchDashboardData();
    const interval = setInterval(fetchDashboardData, 5000);
    return () => clearInterval(interval);
  }, [googleAuthToken, onLogout]);
  // Add useEffect to check for overflow
  useEffect(() => {
    const checkOverflow = () => {
      const measurer = document.createElement("span");
      measurer.style.visibility = "hidden";
      measurer.style.position = "absolute";
      measurer.style.whiteSpace = "nowrap";
      document.body.appendChild(measurer);

      recentActivity.forEach((activity) => {
        const key = `${activity.timestamp}-${activity.customer_name}-${activity.workflow_name}`;
        const element =
          workflowRefs.current[key]?.querySelector(".scroll-text");
        if (element) {
          const computedStyle = window.getComputedStyle(element);

          measurer.style.fontSize = computedStyle.fontSize;
          measurer.style.fontFamily = computedStyle.fontFamily;
          measurer.style.fontWeight = computedStyle.fontWeight;

          measurer.textContent = activity.workflow_name;
          const textWidth = measurer.offsetWidth;

          // Use the parent container's width as the constraint
          const containerWidth = element.parentElement.clientWidth;
          const needsScroll = textWidth > containerWidth;
          element.parentElement.classList.toggle("needs-scroll", needsScroll);
        }
      });

      document.body.removeChild(measurer);
    };

    requestAnimationFrame(checkOverflow);
  }, [recentActivity]);

  const getRelativeTime = (timestamp) => {
    const diff = Date.now() - new Date(timestamp + "Z").getTime();
    const seconds = Math.floor(diff / 1000);

    if (seconds < 60) {
      return `${seconds}s ago`;
    }

    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) {
      return `${minutes}m ago`;
    }

    const hours = Math.floor(minutes / 60);
    if (hours < 24) {
      return `${hours}h ago`;
    }

    const days = Math.floor(hours / 24);
    return `${days}d ago`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setRecentActivity((prev) => [...prev]);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  // Update helper function to replace each letter with an asterisk
  const displayCustomerName = (name) => {
    if (privacyMode) {
      return name.replace(/./g, "*");
    }
    return name;
  };

  // Add helper function to format numbers with commas
  const formatNumberWithCommas = (number) => {
    if (number === undefined || number === null) return "0";
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  if (isLoading) {
    return <div className="loading">Loading dashboard...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="live-dashboard">
      <img src="/qurrent-logo.svg" alt="Qurrent" className="dashboard-logo" />
      <div className="stats-grid">
        <div
          className={`stat-card ${stats._changed?.totalAgents ? "pulse" : ""}`}
        >
          <h3>Agents Deployed</h3>
          <div className="stat-value">
            {formatNumberWithCommas(stats.totalAgents)}
          </div>
        </div>
        <div
          className={`stat-card ${stats._changed?.totalTasks ? "pulse" : ""}`}
        >
          <h3>Tasks Completed</h3>
          <div className="stat-value">
            {formatNumberWithCommas(stats.totalTasks)}
          </div>
        </div>
        <div
          className={`stat-card ${stats._changed?.totalInstances ? "pulse" : ""}`}
        >
          <h3>Workflows Completed</h3>
          <div className="stat-value">
            {formatNumberWithCommas(stats.totalInstances)}
          </div>
        </div>
        <div
          className={`stat-card ${stats._changed?.activeWorkflows ? "pulse" : ""}`}
        >
          <h3>Workflows Managed</h3>
          <div className="stat-value">
            {formatNumberWithCommas(stats.activeWorkflows)}
          </div>
        </div>
      </div>

      <div className="recent-activity">
        <h2>Activity</h2>
        <div className="activity-list">
          {recentActivity.map((activity, index) => {
            const key = `${activity.timestamp}-${activity.customer_name}-${activity.workflow_name}`;
            // Check if this activity item corresponds to an active instance
            const isActive =
              activity.workflow_instance_id &&
              activityPulse.totalTasks.includes(activity.workflow_instance_id);

            return (
              <div
                key={key}
                className={`activity-item ${activity.isNew ? "fade-in" : ""} status-${activity.status} ${isActive && !activity.isNew ? "pulse" : ""}`}
              >
                <div className="activity-content">
                  <span className="timestamp">
                    {getRelativeTime(activity.timestamp)}
                  </span>
                  <span
                    className="workflow-name"
                    ref={(el) => (workflowRefs.current[key] = el)}
                  >
                    <div className="scroll-container needs-scroll">
                      <div
                        className="scroll-text"
                        data-content={activity.workflow_name}
                      >
                        {activity.workflow_name}
                      </div>
                    </div>
                  </span>
                  <span className="customer-name">
                    <strong>
                      {displayCustomerName(activity.customer_name)}
                    </strong>
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default LiveDashboard;
