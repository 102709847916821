import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { JSONTree } from "react-json-tree";
import Prism from "prismjs";
import "prismjs/components/prism-python";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-json";
import "prismjs/themes/prism.css";
import config from "../config";
import { FaSpinner, FaCopy, FaLanguage, FaArrowLeft } from "react-icons/fa";

function AttemptsWithCommands({ agent = null, googleAuthToken }) {
  const [combinedEvents, setCombinedEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);
  const [showFullPrompt, setShowFullPrompt] = useState(false);
  const [selectedOutput, setSelectedOutput] = useState(null);
  const [showOutputPopup, setShowOutputPopup] = useState(false);
  const popupRef = useRef(null);
  const [translatedTexts, setTranslatedTexts] = useState({});
  const [isTranslating, setIsTranslating] = useState({});

  useEffect(() => {
    const fetchData = () => {
      if (!agent) {
        setCombinedEvents([]);
        setHasFetched(false);
        setIsLoading(false);
        return;
      }

      setIsLoading(true);

      if (agent.function_name) {
        // For observables, format the data we already have
        const observableData = [
          {
            ...agent,
            type: "observable",
            created_at: agent.created_at,
            function_name: agent.function_name,
            output: agent.output,
            description: agent.description,
            trigger_observable_id: agent.trigger_observable_id,
            workflow_instance_id: agent.workflow_instance_id,
          },
        ];
        setCombinedEvents(observableData);
        setHasFetched(true);
        setIsLoading(false);
      } else if (agent.output) {
        // For outputs, format the data we already have
        const outputData = [
          {
            ...agent,
            type: "output",
            created_at: agent.created_at,
            output: agent.output,
          },
        ];
        setCombinedEvents(outputData);
        setHasFetched(true);
        setIsLoading(false);
      } else if (agent.error) {
        // For errors, format the data we already have
        const errorData = [
          {
            ...agent,
            type: "error",
            created_at: agent.created_at,
            errorMessage: agent.error,
          },
        ];
        setCombinedEvents(errorData);
        setHasFetched(true);
        setIsLoading(false);
      } else if (agent.identifier_name || agent.type === "identifier") {
        // For identifiers, format the data we already have
        const identifierData = [
          {
            ...agent,
            type: "identifier",
            created_at:
              agent.identifier_created_at ||
              agent.created_at ||
              new Date().toISOString(),
            identifier_name: agent.identifier_name,
            identifier_value: agent.identifier_value,
            identifier_id: agent.identifier_id,
          },
        ];
        setCombinedEvents(identifierData);
        setHasFetched(true);
        setIsLoading(false);
      } else if (
        (agent.agent_name && agent.type === "consoleAgent") ||
        agent.type === "consoleAgent"
      ) {
        // For console agents, format the data we already have
        console.log("Processing console agent:", agent);
        const consoleAgentData = [
          {
            ...agent,
            id: agent.id || `console-${Date.now()}`,
            type: "consoleAgent",
            created_at: agent.created_at || new Date().toISOString(),
            agent_name: agent.agent_name || agent.name || "Console Agent",
            message: agent.message || agent.content || agent.output || "",
          },
        ];
        console.log("Console agent data:", consoleAgentData);
        setCombinedEvents(consoleAgentData);
        setHasFetched(true);
        setIsLoading(false);
      } else {
        // Existing agent data fetching logic
        Promise.all([
          axios.get(`${config.apiBaseUrl}/llm_calls/${agent.id}`, {
            headers: { Authorization: `Bearer ${googleAuthToken}` },
          }),
          axios.get(`${config.apiBaseUrl}/actions/${agent.id}`, {
            headers: { Authorization: `Bearer ${googleAuthToken}` },
          }),
        ])
          .then(([llmResponse, actionsResponse]) => {
            if (!llmResponse || !llmResponse.data) {
              console.error("LLM response is undefined or missing data");
              throw new Error("Invalid LLM response");
            }
            if (!actionsResponse || !actionsResponse.data) {
              console.error("Actions response is undefined or missing data");
              throw new Error("Invalid actions response");
            }

            const llmCalls = llmResponse.data.map((call) => ({
              ...call,
              type: "llm_call",
            }));
            const actions = actionsResponse.data.map((action) => ({
              ...action,
              type: "action",
            }));

            const combined = [...llmCalls, ...actions].sort((a, b) => {
              const dateA = new Date(a.created_at);
              const dateB = new Date(b.created_at);

              // Sort chronologically (oldest first)
              return dateA - dateB;
            });

            setCombinedEvents(combined);
            setHasFetched(true);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error("Error fetching data", error);
            setError("Error fetching data: " + error.message);
            setIsLoading(false);
          });
      }
    };

    fetchData();
  }, [agent, googleAuthToken]);

  // Function to detect if text is not in English
  const isNonEnglish = (text) => {
    if (!text || typeof text !== "string") return false;

    // More robust detection approach:
    // 1. Check for common non-English character patterns
    const hasNonEnglishChars = /[áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœ]/i.test(text);

    // 2. Look for common patterns in non-English languages
    const hasNonEnglishPatterns =
      /\b(de la|das|der|das|die|el|la|los|las|con|por|para|em|na|no|como|não|muito|está|obrigado|gracias|merci)\b/.test(
        text.toLowerCase()
      );

    // 3. Fall back to non-ASCII counting for other languages
    const nonAsciiCount = (text.match(/[^\u0000-\u007F]/g) || []).length;
    const highNonAsciiRatio = nonAsciiCount > text.length * 0.05; // Lower threshold to 5%

    const result =
      hasNonEnglishChars || hasNonEnglishPatterns || highNonAsciiRatio;

    return result;
  };

  // Function to translate text to English
  const translateToEnglish = async (text, id, jsonPath = null) => {
    if (!text || typeof text !== "string") return text;

    setIsTranslating((prev) => ({ ...prev, [id]: true }));

    try {
      // Use a translation API
      const response = await axios.post(
        `${config.apiBaseUrl}/translate`,
        { text: text, target_language: "en" },
        { headers: { Authorization: `Bearer ${googleAuthToken}` } }
      );

      let translatedText = null;
      if (response.data && response.data.translated_text) {
        translatedText = response.data.translated_text;
      } else if (response.data && response.data.translated) {
        // Handle alternative response format
        translatedText = response.data.translated;
      } else {
        console.error(
          "Translation response missing expected fields:",
          response.data
        );
        translatedText = `[Translation received but in unexpected format]`;
      }

      // If we have a JSON path, update only that specific path in the original JSON
      if (jsonPath) {
        const [originalJson, path] = jsonPath;
        let jsonCopy = JSON.parse(JSON.stringify(originalJson)); // Deep clone

        // Update specific path in the JSON
        if (path === "response") {
          jsonCopy.response = translatedText;
          setTranslatedTexts((prev) => ({
            ...prev,
            [id]: JSON.stringify(jsonCopy, null, 2),
          }));
        } else {
          // For other nested paths if needed in the future
          setTranslatedTexts((prev) => ({
            ...prev,
            [id]: translatedText, // Fallback to simple replacement
          }));
        }
      } else {
        // Simple text replacement if no JSON path
        setTranslatedTexts((prev) => ({
          ...prev,
          [id]: translatedText,
        }));
      }
    } catch (error) {
      console.error("Translation error:", error);
      // Log more details about the error
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      }
      // Fallback - use a simple note if translation fails
      setTranslatedTexts((prev) => ({
        ...prev,
        [id]: `[Translation failed: ${error.message}]`,
      }));
    } finally {
      setIsTranslating((prev) => ({ ...prev, [id]: false }));
    }
  };

  // Function to toggle translation for a specific text
  const toggleTranslation = async (text, id, jsonPath = null) => {
    if (translatedTexts[id]) {
      // Translation exists, no need to translate again
      return;
    }

    await translateToEnglish(text, id, jsonPath);
  };

  const formatCompletion = (completion) => {
    if (!completion) return "No completion";
    try {
      if (typeof completion === "string") {
        // Parse the completion if it's a JSON string
        try {
          const parsed = JSON.parse(completion);
          // Check for non-English text in the response field if it exists
          if (parsed.response && typeof parsed.response === "string") {
            const nonEnglish = isNonEnglish(parsed.response);
            // Store the nonEnglish flag without modifying the original object
            return {
              text: JSON.stringify(parsed, null, 2),
              isNonEnglish: nonEnglish,
            };
          }
          return {
            text: JSON.stringify(parsed, null, 2),
            isNonEnglish: false,
          };
        } catch (e) {
          return {
            text: completion,
            isNonEnglish: isNonEnglish(completion),
          };
        }
      } else if (typeof completion === "object") {
        // Check for non-English text in the response field if it exists
        const nonEnglish =
          completion.response && typeof completion.response === "string"
            ? isNonEnglish(completion.response)
            : false;
        return {
          text: JSON.stringify(completion, null, 2),
          isNonEnglish: nonEnglish,
        };
      }
      return {
        text: String(completion),
        isNonEnglish: false,
      };
    } catch (error) {
      console.error("Error formatting completion:", error);
      return {
        text: "Error formatting completion",
        isNonEnglish: false,
      };
    }
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowFullPrompt(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleFullPrompt = (event, prompt) => {
    event.preventDefault();
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    setSelectedPrompt(prompt);
    setShowFullPrompt(!showFullPrompt);
  };

  const toggleOutputPopup = (event, output) => {
    if (event.stopPropagation) {
      event.stopPropagation();
    }
    if (output.length > 2500) {
      setSelectedOutput(output);
      setShowOutputPopup(!showOutputPopup);
    }
  };

  const renderCustomNode = (nodeProps) => {
    const { value, nodeType } = nodeProps;

    // Check if it's a string and contains base64 image data
    if (
      nodeType === "string" &&
      typeof value === "string" &&
      value.startsWith("data:image") &&
      value.includes("base64")
    ) {
      return (
        <div>
          <img
            src={value}
            alt="Base64 encoded content"
            style={{ maxWidth: "100%", maxHeight: "300px", marginTop: "10px" }}
          />
          <div style={{ fontSize: "0.8em", color: "#666", marginTop: "5px" }}>
            (Base64 image - click to copy)
            <button
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(value);
              }}
              style={{
                marginLeft: "10px",
                background: "transparent",
                border: "none",
                cursor: "pointer",
                padding: "2px",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              title="Copy to clipboard"
            >
              <FaCopy size={12} style={{ color: "#666" }} />
            </button>
          </div>
        </div>
      );
    }

    // Default rendering for other node types
    return undefined;
  };

  const renderEvent = (event) => {
    const isUrl = (string) => {
      try {
        const url = new URL(string);
        // Only consider it a URL if it has a valid protocol (http or https)
        return url.protocol === "http:" || url.protocol === "https:";
      } catch (_) {
        return false;
      }
    };

    if (event.type === "observable") {
      const outputLength = event.output?.length || 0;
      const shouldTruncate = outputLength > 2500;
      const displayOutput = shouldTruncate
        ? event.output.slice(0, 2500) + "..."
        : event.output;

      return (
        <button
          className="attempt"
          onClick={(e) => toggleOutputPopup(e, event.output)}
        >
          <strong>Observable ID:</strong> {event.id}
          <br />
          <strong>Date:</strong>{" "}
          {new Date(event.created_at + "Z").toLocaleString()}
          <br />
          <strong>Function Name:</strong> {event.function_name}
          <br />
          {event.description && (
            <>
              <strong>Description:</strong> {event.description}
              <br />
            </>
          )}
          {(event.trigger_action_id !== null &&
            event.trigger_action_id !== undefined) ||
          (event.trigger_workflow_instance_id !== null &&
            event.trigger_workflow_instance_id !== undefined) ||
          (event.trigger_observable_id !== null &&
            event.trigger_observable_id !== undefined) ||
          (event.trigger_console_agent_id !== null &&
            event.trigger_console_agent_id !== undefined) ? (
            <>
              <strong>Triggered By:</strong>{" "}
              {event.trigger_action_id !== null &&
              event.trigger_action_id !== undefined
                ? `action ID ${event.trigger_action_id}`
                : event.trigger_workflow_instance_id !== null &&
                    event.trigger_workflow_instance_id !== undefined
                  ? `workflow ID ${event.trigger_workflow_instance_id}`
                  : event.trigger_observable_id !== null &&
                      event.trigger_observable_id !== undefined
                    ? `observable ID ${event.trigger_observable_id}`
                    : `console agent ID ${event.trigger_console_agent_id}`}
              <br />
            </>
          ) : null}
          {event.output && (
            <>
              <strong>Output:</strong>
              <br />
              <br />
              <div style={{ position: "relative" }}>
                <pre
                  style={{
                    whiteSpace: "pre-wrap",
                    border: "1px solid black",
                    backgroundColor: "white",
                    margin: 0,
                    paddingRight: "32px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    minHeight: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <code>{displayOutput}</code>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(event.output);
                    }}
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)", // Center vertically
                      right: "8px",
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                      padding: "4px",
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minWidth: "unset",
                    }}
                    title="Copy to clipboard"
                  >
                    <FaCopy size={14} style={{ color: "#666" }} />
                  </button>
                </pre>
              </div>
            </>
          )}
          {selectedOutput === event.output &&
            showOutputPopup &&
            event.output.length > 2500 && (
              <div
                className="popup"
                ref={popupRef}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowOutputPopup(false);
                }}
              >
                <div
                  className="popup-content"
                  onClick={(e) => e.stopPropagation()}
                >
                  <h3>Full Output:</h3>
                  <pre style={{ whiteSpace: "pre-wrap" }}>
                    <code>{event.output}</code>
                  </pre>
                  <button
                    className="close-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowOutputPopup(false);
                    }}
                  >
                    <strong>Close</strong>
                  </button>
                </div>
              </div>
            )}
        </button>
      );
    } else if (event.type === "llm_call") {
      let formattedCompletion = formatCompletion(event.completion);
      const completionId = `completion-${event.id}`;

      // Extract and check if the completion contains non-English text
      const hasNonEnglishCompletion = formattedCompletion.isNonEnglish;
      const textToDisplay =
        translatedTexts[completionId] || formattedCompletion.text;

      const showTranslateButton =
        hasNonEnglishCompletion && !translatedTexts[completionId];
      const showOriginalButton =
        hasNonEnglishCompletion && translatedTexts[completionId];

      return (
        <button
          className={`attempt attempt-${event.status}`}
          onClick={(e) => toggleFullPrompt(e, event.input)}
        >
          <strong>LLM Call ID: </strong> {event.id}
          <br />
          <strong>Date:</strong>{" "}
          {new Date(event.created_at + "Z").toLocaleString()}
          <br />
          <strong>Model Name:</strong> {event.model_name}
          <br />
          <strong>Temperature:</strong> {event.temperature}
          <br />
          <strong>Input Tokens:</strong> {event.input_tokens}
          <br />
          <strong>Completion Tokens:</strong> {event.completion_tokens}
          <br />
          <strong>Cost: </strong>$
          {Number(event.cost)
            .toFixed(6)
            .replace(/\.?0+$/, "")}
          <br />
          <strong>Triggered By:</strong>{" "}
          {event.trigger_action_id !== null
            ? `action ID ${event.trigger_action_id}`
            : event.trigger_workflow_instance_id !== null
              ? `workflow ID ${event.trigger_workflow_instance_id}`
              : event.trigger_observable_id !== null
                ? `observable ID ${event.trigger_observable_id}`
                : "N/A"}
          <br />
          {/* Display LLM call details if available */}
          {event.llm_call_details && event.llm_call_details.length > 0 && (
            <>
              <strong>Additional Details:</strong>{" "}
              {event.llm_call_details.map((detail, index) => (
                <span key={detail.id}>
                  {detail.name}: <em>{detail.value}</em>
                  {index < event.llm_call_details.length - 1 ? ", " : ""}
                </span>
              ))}
              <br />
            </>
          )}
          <br />
          <strong>Completion:</strong>
          <br />
          <br />
          <div style={{ position: "relative" }}>
            <pre
              style={{
                whiteSpace: "pre-wrap",
                border: "1px solid black",
                backgroundColor: "white",
                margin: 0,
                position: "relative",
                paddingRight: "48px",
                paddingBottom:
                  showTranslateButton || showOriginalButton ? "16px" : "8px",
              }}
            >
              <code
                dangerouslySetInnerHTML={{
                  __html: Prism.highlight(
                    textToDisplay,
                    Prism.languages.json,
                    "json"
                  ),
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    // Copy the translated text if it exists, otherwise copy the original
                    navigator.clipboard.writeText(
                      translatedTexts[completionId] || formattedCompletion.text
                    );
                  }}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    padding: "4px",
                    width: "24px",
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "unset",
                  }}
                  title="Copy to clipboard"
                >
                  <FaCopy size={14} style={{ color: "#666" }} />
                </button>
                {showTranslateButton && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      try {
                        // Only translate the response field in the JSON
                        const parsedCompletion = JSON.parse(event.completion);
                        if (
                          parsedCompletion &&
                          parsedCompletion.response &&
                          typeof parsedCompletion.response === "string"
                        ) {
                          toggleTranslation(
                            parsedCompletion.response,
                            completionId,
                            [parsedCompletion, "response"]
                          );
                        } else {
                          // Fallback to translating the entire text
                          toggleTranslation(
                            formattedCompletion.text,
                            completionId
                          );
                        }
                      } catch (e) {
                        // If parsing fails, translate the entire text
                        toggleTranslation(
                          formattedCompletion.text,
                          completionId
                        );
                      }
                    }}
                    style={{
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                      padding: "4px",
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minWidth: "unset",
                    }}
                    title="Translate to English"
                    disabled={isTranslating[completionId]}
                  >
                    {isTranslating[completionId] ? (
                      <FaSpinner size={14} style={{ color: "#666" }} />
                    ) : (
                      <FaLanguage size={14} style={{ color: "#666" }} />
                    )}
                  </button>
                )}
                {showOriginalButton && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      setTranslatedTexts((prev) => {
                        const newState = { ...prev };
                        delete newState[completionId];
                        return newState;
                      });
                    }}
                    style={{
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                      padding: "4px",
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minWidth: "unset",
                    }}
                    title="Show original"
                  >
                    <FaArrowLeft size={12} style={{ color: "#666" }} />
                  </button>
                )}
              </div>
            </pre>
          </div>
        </button>
      );
    } else if (event.type === "action") {
      const { type, ...actionWithoutType } = event;
      return (
        <button className={`attempt attempt-${event.status}`}>
          <strong>Action ID:</strong> {event.id}
          <br />
          <strong>Date:</strong>{" "}
          {new Date(event.created_at + "Z").toLocaleString()}
          <br />
          <strong>LLM Call ID:</strong> {event.llm_call_id || "N/A"}
          <br />
          <strong>Action:</strong>
          <br />
          <br />
          <div style={{ position: "relative" }}>
            <pre
              style={{
                whiteSpace: "pre-wrap",
                border: "1px solid black",
                backgroundColor: "white",
                margin: 0,
                position: "relative",
                paddingRight: "32px",
              }}
            >
              <code
                dangerouslySetInnerHTML={{
                  __html: Prism.highlight(
                    JSON.stringify(actionWithoutType, null, 2),
                    Prism.languages.json,
                    "json"
                  ),
                }}
              />
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(
                    JSON.stringify(actionWithoutType, null, 2)
                  );
                }}
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  padding: "4px",
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "unset",
                }}
                title="Copy to clipboard"
              >
                <FaCopy size={14} style={{ color: "#666" }} />
              </button>
            </pre>
          </div>
        </button>
      );
    } else if (event.type === "output") {
      return (
        <button className="attempt">
          <strong>Output ID:</strong> {event.id}
          <br />
          <strong>Date:</strong>{" "}
          {new Date(event.created_at + "Z").toLocaleString()}
          <br />
          {event.key && (
            <>
              <strong>Key:</strong> {event.key}
              <br />
            </>
          )}
          <strong>Output:</strong>
          <br />
          <br />
          <div style={{ position: "relative" }}>
            <pre
              style={{
                whiteSpace: "pre-wrap",
                border: "1px solid black",
                backgroundColor: "white",
                margin: 0,
                paddingRight: "32px",
                minHeight: "36px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <code>
                {isUrl(event.output) ? (
                  <a
                    href={event.output}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {event.output}
                  </a>
                ) : (
                  event.output
                )}
              </code>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(event.output);
                }}
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  padding: "4px",
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "unset",
                }}
                title="Copy to clipboard"
              >
                <FaCopy size={14} style={{ color: "#666" }} />
              </button>
            </pre>
          </div>
        </button>
      );
    } else if (event.type === "error") {
      return (
        <button className="attempt">
          <strong>Error ID:</strong> {event.id}
          <br />
          <strong>Date:</strong>{" "}
          {new Date(event.created_at + "Z").toLocaleString()}
          <br />
          <strong>Error Message:</strong>
          <br />
          <div style={{ position: "relative" }}>
            <pre
              style={{
                whiteSpace: "pre-wrap",
                border: "1px solid black",
                backgroundColor: "white",
                margin: 0,
                paddingRight: "32px",
              }}
            >
              <code>{event.errorMessage}</code>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(event.errorMessage);
                }}
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  background: "transparent",
                  border: "none",
                  cursor: "pointer",
                  padding: "4px",
                  width: "24px",
                  height: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  minWidth: "unset",
                }}
                title="Copy to clipboard"
              >
                <FaCopy size={14} style={{ color: "#666" }} />
              </button>
            </pre>
          </div>
        </button>
      );
    } else if (event.type === "identifier") {
      return (
        <button className="attempt">
          <strong>Identifier ID:</strong> {event.identifier_id}
          <br />
          <strong>Date:</strong>{" "}
          {new Date(event.created_at + "Z").toLocaleString()}
          <br />
          <strong>Name:</strong> {event.identifier_name}
          <br />
          {event.identifier_value && (
            <>
              <strong>Value:</strong>
              <br />
              <br />
              <div style={{ position: "relative" }}>
                <pre
                  style={{
                    whiteSpace: "pre-wrap",
                    border: "1px solid black",
                    backgroundColor: "white",
                    margin: 0,
                    paddingRight: "32px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    minHeight: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <code>{event.identifier_value}</code>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(event.identifier_value);
                    }}
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      right: "8px",
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                      padding: "4px",
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minWidth: "unset",
                    }}
                    title="Copy to clipboard"
                  >
                    <FaCopy size={14} style={{ color: "#666" }} />
                  </button>
                </pre>
              </div>
            </>
          )}
        </button>
      );
    } else if (event.type === "consoleAgent") {
      return (
        <button className="attempt">
          <strong>Console Agent ID:</strong> {event.id}
          <br />
          <strong>Date:</strong>{" "}
          {new Date(event.created_at + "Z").toLocaleString()}
          <br />
          <strong>Agent Name:</strong> {event.agent_name || "Console Agent"}
          <br />
          {event.message && (
            <>
              <strong>Description:</strong>
              <br />
              <br />
              <div style={{ position: "relative" }}>
                <pre
                  style={{
                    whiteSpace: "pre-wrap",
                    border: "1px solid black",
                    backgroundColor: "white",
                    margin: 0,
                    paddingRight: "32px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                    minHeight: "10px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <code>{event.message || event.content || event.output}</code>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(
                        event.message || event.content || event.output
                      );
                    }}
                    style={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      right: "8px",
                      background: "transparent",
                      border: "none",
                      cursor: "pointer",
                      padding: "4px",
                      width: "24px",
                      height: "24px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      minWidth: "unset",
                    }}
                    title="Copy to clipboard"
                  >
                    <FaCopy size={14} style={{ color: "#666" }} />
                  </button>
                </pre>
              </div>
            </>
          )}
        </button>
      );
    }
  };

  if (isLoading) {
    return (
      <div className="AttemptsWithCommands">
        <div className="loading-container">
          <FaSpinner className="loading-icon" />
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return <div className="AttemptsWithCommands">{error}</div>;
  }

  const theme = {
    base00: "#f7f7f7" /* background color */,
    base03: "#f7f7f7" /* item number color */,
    base0B: "#2b2b2b" /* text color */,
    base0D: "#247BA0" /* key color */,
  };

  // Function to render images in prompt popup
  const renderPromptImages = (input) => {
    const images = [];
    const imageUrls = [];

    // Function to recursively scan for images
    const findImages = (obj, path = []) => {
      if (!obj) return;

      if (typeof obj === "object") {
        for (const [key, val] of Object.entries(obj)) {
          const newPath = [...path, key];

          // Handle image_url objects (both base64 and regular URLs)
          if (
            key === "url" &&
            typeof val === "string" &&
            path.length > 0 &&
            (path[path.length - 1] === "image_url" || obj.type === "image_url")
          ) {
            if (val.startsWith("data:image") && val.includes("base64")) {
              imageUrls.push({ type: "base64_url", url: val });
            } else {
              imageUrls.push({ type: "url", url: val });
            }
          }
          // Handle standalone base64 images (not in image_url objects)
          else if (
            typeof val === "string" &&
            val.startsWith("data:image") &&
            val.includes("base64") &&
            !(
              key === "url" &&
              path.length > 0 &&
              (path[path.length - 1] === "image_url" ||
                obj.type === "image_url")
            )
          ) {
            images.push({ type: "base64", url: val });
          } else {
            findImages(val, newPath);
          }
        }
      }
    };

    findImages(JSON.parse(input));

    // Combine both image types
    const allImages = [...images, ...imageUrls];

    if (allImages.length === 0) return null;

    return (
      <>
        <h4 style={{ marginTop: "20px" }}>Images in Prompt:</h4>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          {allImages.map((img, idx) => {
            let typeLabel = "";

            if (img.type === "base64") {
              typeLabel = `Image ${images.indexOf(img) + 1}`;
            } else if (img.type === "base64_url") {
              typeLabel = `Image URL ${imageUrls.indexOf(img) + 1}`;
            } else {
              // regular url
              typeLabel = `Image URL ${imageUrls.indexOf(img) + 1}`;
            }

            return (
              <div
                key={idx}
                style={{
                  border: "1px solid #ddd",
                  padding: "10px",
                  borderRadius: "4px",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    marginBottom: "5px",
                    color: "#444",
                  }}
                >
                  {typeLabel}
                </div>
                <img
                  src={img.url}
                  alt={typeLabel}
                  style={{
                    maxWidth: "100%",
                    maxHeight: "300px",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "8px",
                  }}
                >
                  <span style={{ fontSize: "0.9em", color: "#666" }}>
                    Referenced in prompt as {typeLabel}
                  </span>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(img.url);
                    }}
                    style={{
                      background: "transparent",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      padding: "4px 8px",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      fontSize: "0.85em",
                    }}
                    title={
                      img.type === "base64" || img.type === "base64_url"
                        ? "Copy base64 to clipboard"
                        : "Copy URL to clipboard"
                    }
                  >
                    <FaCopy size={12} />{" "}
                    {img.type === "base64" || img.type === "base64_url"
                      ? "Copy base64"
                      : "Copy URL"}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  // Function to create value renderer for JSON Tree
  const createValueRenderer = (input) => {
    return (raw, value, ...keyPath) => {
      // Replace base64 image strings with a placeholder
      if (
        typeof value === "string" &&
        value.startsWith("data:image") &&
        value.includes("base64")
      ) {
        // For base64 images, check if they're in an image_url object
        const isInImageUrl =
          keyPath.length >= 2 &&
          keyPath[0] === "url" &&
          (keyPath.includes("image_url") ||
            keyPath.some((p) => p === "type" && value === "image_url"));

        // Create a consistent hash for this image to use as an identifier
        const imgIndex = (() => {
          // Find the index of this image in the full structure
          const parsedInput = JSON.parse(input);
          const images = [];

          const findBase64Images = (obj, path = []) => {
            if (!obj) return;

            if (typeof obj === "object") {
              for (const [key, val] of Object.entries(obj)) {
                const newPath = [...path, key];
                if (
                  key === "url" &&
                  typeof val === "string" &&
                  val.startsWith("data:image") &&
                  val.includes("base64") &&
                  path.length > 0 &&
                  (path[path.length - 1] === "image_url" ||
                    obj.type === "image_url")
                ) {
                  images.push(val);
                } else {
                  findBase64Images(val, newPath);
                }
              }
            }
          };

          findBase64Images(parsedInput);
          return images.indexOf(value) + 1;
        })();

        // If it's in an image_url, mark as Image URL, otherwise as regular Image
        if (isInImageUrl || keyPath[0] === "url") {
          return `[Image URL ${imgIndex} - shown in Images section below]`;
        } else {
          return `[Image ${imgIndex} - shown in Images section below]`;
        }
      }

      // Check if this is part of an image_url type object (non-base64)
      if (
        typeof value === "string" &&
        !value.startsWith("data:image") &&
        keyPath.length >= 2 &&
        keyPath[0] === "url" &&
        keyPath.includes("image_url")
      ) {
        // Find the index of this URL in all image URLs
        const imgIndex = (() => {
          const parsedInput = JSON.parse(input);
          const imageUrls = [];

          const findImageUrls = (obj, path = []) => {
            if (!obj) return;

            if (typeof obj === "object") {
              for (const [key, val] of Object.entries(obj)) {
                const newPath = [...path, key];
                if (
                  key === "url" &&
                  typeof val === "string" &&
                  !val.startsWith("data:image") &&
                  path.length > 0 &&
                  (path[path.length - 1] === "image_url" ||
                    obj.type === "image_url")
                ) {
                  imageUrls.push(val);
                } else {
                  findImageUrls(val, newPath);
                }
              }
            }
          };

          findImageUrls(parsedInput);
          return imageUrls.indexOf(value) + 1;
        })();

        return `[Image URL ${imgIndex} - shown in Images section below]`;
      }

      // If the value is a string and looks non-English, offer translation
      if (typeof value === "string" && isNonEnglish(value)) {
        // Replace btoa with a safe encoding function
        const valueId = `prompt-${encodeURIComponent(value.substring(0, 20))}`;
        const translatedValue = translatedTexts[valueId] || value;

        if (translatedTexts[valueId]) {
          // If already translated, show with option to view original
          return (
            <span
              style={{
                display: "inline-flex",
                alignItems: "center",
                maxWidth: "fit-content",
              }}
            >
              {translatedValue}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setTranslatedTexts((prev) => {
                    const newState = { ...prev };
                    delete newState[valueId];
                    return newState;
                  });
                }}
                style={{
                  marginLeft: "5px",
                  background: "transparent",
                  border: "1px solid #ccc",
                  borderRadius: "3px",
                  cursor: "pointer",
                  padding: "2px 4px",
                  display: "inline-flex",
                  alignItems: "center",
                  width: "auto",
                  flexShrink: 0,
                }}
              >
                <FaArrowLeft size={10} style={{ color: "#666" }} />
              </button>
            </span>
          );
        } else {
          // If not translated, show with option to translate
          return (
            <span
              style={{
                display: "inline-flex",
                alignItems: "center",
                maxWidth: "fit-content",
              }}
            >
              {value}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleTranslation(value, valueId);
                }}
                style={{
                  marginLeft: "5px",
                  background: "transparent",
                  border: "1px solid #ccc",
                  borderRadius: "3px",
                  cursor: "pointer",
                  padding: "2px 4px",
                  display: "inline-flex",
                  alignItems: "center",
                  width: "auto",
                  flexShrink: 0,
                }}
                disabled={isTranslating[valueId]}
              >
                {isTranslating[valueId] ? (
                  <FaSpinner size={10} style={{ color: "#666" }} />
                ) : (
                  <FaLanguage size={10} style={{ color: "#666" }} />
                )}
              </button>
            </span>
          );
        }
      }

      return raw;
    };
  };

  return (
    <div className="AttemptsWithCommands">
      {combinedEvents.map((event) => (
        <div key={`${event.type}-${event.id}`}>
          {renderEvent(event)}
          {selectedPrompt === event.input && showFullPrompt && (
            <div
              className="popup"
              ref={popupRef}
              onClick={(e) => {
                e.stopPropagation();
                setShowFullPrompt(false);
              }}
              style={{ zIndex: 9999 }}
            >
              <div
                className="popup-content"
                onClick={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
              >
                <h3>Full Prompt:</h3>
                <JSONTree
                  data={JSON.parse(event.input)}
                  theme={theme}
                  invertTheme={false}
                  shouldExpandNodeInitially={() => true}
                  valueRenderer={createValueRenderer(event.input)}
                  getItemString={(type, data, itemType, itemString) => (
                    <span>
                      {itemType} {itemString}
                    </span>
                  )}
                  renderNode={renderCustomNode}
                />

                {/* Add images section */}
                <div className="base64-images-section">
                  {renderPromptImages(event.input)}
                </div>

                <button
                  className="close-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowFullPrompt(false);
                  }}
                >
                  <strong>Close</strong>
                </button>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default AttemptsWithCommands;
