import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useContext,
} from "react";
import axios from "axios";
import "../App.css";
import Select from "react-select";
import EnvSwitcher from "./EnvSwitcher";
import config from "../config";
import { FaSpinner, FaCopy } from "react-icons/fa";
import { EnvironmentContext } from "../contexts/EnvironmentContext";

// Custom hook for fetching filter options
function useFilterOptions(googleAuthToken, onLogout) {
  const [filterOptions, setFilterOptions] = useState({});
  const { env } = useContext(EnvironmentContext);

  useEffect(() => {
    if (!googleAuthToken) return;

    const fetchOptions = async (endpoint) => {
      try {
        const response = await axios.get(`${config.apiBaseUrl}${endpoint}`, {
          headers: { Authorization: `Bearer ${googleAuthToken}` },
        });
        return response.data;
      } catch (error) {
        console.error(`Error fetching options from ${endpoint}`, error);
        // Check if the error is due to unauthorized access
        if (error.response && error.response.status === 401) {
          console.log("Unauthorized access in filter options. Logging out...");
          onLogout();
        }
        return [];
      }
    };

    Promise.all([
      fetchOptions("/customer-names"),
      fetchOptions("/workflows"),
      fetchOptions("/workflow-statuses"),
    ]).then(([customerNames, workflowNames, statuses]) => {
      setFilterOptions({
        customer_name: customerNames,
        workflow_name: workflowNames,
        status: statuses,
      });
    });
  }, [googleAuthToken, env, onLogout]);

  return filterOptions;
}

function Runs({
  onRunClick,
  selectedRun,
  googleAuthToken,
  page,
  setPage,
  onRefresh,
  onLogout,
}) {
  const [runs, setRuns] = useState([]);
  const [maxPage, setMaxPage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  const filterOptions = useFilterOptions(googleAuthToken, onLogout);

  const runsPerPage = 10;

  const loadData = useCallback(() => {
    if (!googleAuthToken) return;
    setLoading(true);
    // Clear previous runs when starting a new fetch
    setRuns([]);
    onRefresh();

    let url =
      config.apiBaseUrl +
      `/filter/runs?page=${page}&limit=${runsPerPage}&${new URLSearchParams(
        Object.entries(filters).reduce(
          (params, [column, values]) => ({
            ...params,
            [column]: values.join(","),
          }),
          {}
        )
      ).toString()}`;

    if (searchQuery) {
      url += `&search=${searchQuery}`;
    }

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${googleAuthToken}`,
        },
      })
      .then((runsResponse) => {
        const runsData = runsResponse.data.data;

        // Display runs immediately with default total_cost value
        setRuns(runsData.map((run) => ({ ...run, total_cost: "Loading..." })));
        setMaxPage(Math.ceil(runsResponse.data.total / runsPerPage));
        setLoading(false);

        // Then fetch cost data for each run and update individually
        runsData.forEach((run) => {
          axios
            .get(`${config.apiBaseUrl}/total_cost/${run.id}`, {
              headers: {
                Authorization: `Bearer ${googleAuthToken}`,
              },
            })
            .then((res) => {
              setRuns((currentRuns) =>
                currentRuns.map((r) =>
                  r.id === run.id
                    ? { ...r, total_cost: res.data.total_cost }
                    : r
                )
              );
            })
            .catch((error) => {
              console.error(`Error fetching cost for run ${run.id}:`, error);
              // Also check for auth errors in cost fetching
              if (error.response && error.response.status === 401) {
                console.log(
                  "Unauthorized access in cost fetch. Logging out..."
                );
                onLogout();
                return;
              }
              setRuns((currentRuns) =>
                currentRuns.map((r) =>
                  r.id === run.id ? { ...r, total_cost: "Error" } : r
                )
              );
            });
        });
      })
      .catch((error) => {
        console.error("Error fetching runs data:", error);
        setLoading(false);

        // Check if the error is due to unauthorized access
        if (error.response && error.response.status === 401) {
          console.log("Unauthorized access. Logging out...");
          onLogout();
        }
      });
  }, [page, filters, searchQuery, googleAuthToken, onRefresh, onLogout]);
  useEffect(() => {
    if (googleAuthToken) {
      loadData();
    }
  }, [loadData, googleAuthToken]);

  // Memoize the filter change handler
  const handleFilterChange = useMemo(() => {
    return (column) => (selectedOptions) => {
      const value = selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [];
      setFilters((prev) => {
        // Reset page first, then update filters to ensure they happen together
        setPage(1);
        return { ...prev, [column]: value };
      });
    };
  }, [setPage]);

  // Handle search query change
  const handleSearchChange = (e) => {
    setPage(1); // Reset page first
    setSearchQuery(e.target.value);
  };

  const handleNextClick = () => {
    if (page < maxPage) setPage(page + 1);
  };

  const handlePrevClick = () => {
    if (page > 1) setPage(page - 1);
  };

  const getDisplayName = (column) => {
    return column
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  return (
    <div className="Runs">
      <h2>Runs</h2>
      <div className="controls-container">
        <EnvSwitcher onEnvironmentSwitch={loadData} />
      </div>
      <div className="controls-container">
        <button onClick={loadData} className="refresh-button">
          Refresh
        </button>
        <input
          type="text"
          className="search-input"
          placeholder="Search by Workflow ID"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        {["customer_name", "workflow_name", "status"].map((column) => (
          <Select
            key={column}
            isMulti
            placeholder={`Select ${getDisplayName(column)}...`}
            value={filters[column]?.map((value) => ({ label: value, value }))}
            onChange={handleFilterChange(column)}
            options={filterOptions[column]?.map((value) => ({
              label: value,
              value,
            }))}
          />
        ))}
        <button
          className="page-button"
          onClick={handlePrevClick}
          disabled={page === 1}
        >
          Previous
        </button>
        <button
          className="page-button"
          onClick={handleNextClick}
          disabled={page === maxPage}
        >
          Next
        </button>
      </div>
      <div className="runs-container">
        {loading ? (
          <div className="loading-container">
            <FaSpinner className="loading-icon" />
            <p>Loading...</p>
          </div>
        ) : (
          runs.map((run) => (
            <button
              key={run.id}
              className={`run ${selectedRun?.id === run.id ? "selected" : ""} ${
                run.status
                  ? "run-" + run.status.toLowerCase().replace(" ", "-")
                  : ""
              }`}
              onClick={() => onRunClick(run)}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <strong>Workflow ID:</strong> {run.id}
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(run.id);
                  }}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    padding: "4px",
                    width: "24px",
                    height: "24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: "unset",
                  }}
                  title="Copy Workflow ID"
                >
                  <FaCopy size={14} style={{ color: "#666" }} />
                </button>
              </div>
              <br />
              <strong>Customer Name:</strong> {run.customer_name} <br />
              <strong>Workflow:</strong> {run.workflow_name} <br />
              <strong>Workflow Version:</strong> {run.workflow_version} <br />
              <strong>Qurrent Version:</strong> {run.qurrent_version} <br />
              <strong>Started At:</strong>{" "}
              {new Date(run.created_at + "Z").toLocaleString()}
              <br />
              <strong>Ended At:</strong>{" "}
              {run.end_time
                ? new Date(run.end_time + "Z").toLocaleString()
                : "N/A"}
              <br />
              <strong>Total Cost:</strong> $
              {run.total_cost === "Loading..."
                ? "..."
                : run.total_cost === "Error"
                  ? "Error"
                  : Number(run.total_cost)
                      .toFixed(6)
                      .replace(/\.?0+$/, "")}
            </button>
          ))
        )}
      </div>
    </div>
  );
}

export default Runs;
