import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { EnvironmentContext } from "../contexts/EnvironmentContext";
import config from "../config";
import EnvSwitcher from "./EnvSwitcher";

function AdminPage({ googleAuthToken }) {
  const [addtionalApiKeyTag, setAddtionalApiKeyTag] = useState("");
  const [additionalKeyResult, setAdditionalKeyResult] = useState("");
  const [customers, setCustomers] = useState([]);
  const [customerIdInput, setCustomerIdInput] = useState("");
  const [statusCustomerId, setStatusCustomerId] = useState("");
  const [statusValue, setStatusValue] = useState("active");
  const [statusUpdateResult, setStatusUpdateResult] = useState("");

  const { env, switchEnvironment } = useContext(EnvironmentContext);

  const fetchCustomers = useCallback(async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/customers`, {
        params: { includeInactive: "true" },
        headers: {
          Authorization: `Bearer ${googleAuthToken}`,
        },
      });
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  }, [googleAuthToken]);

  useEffect(() => {
    fetchCustomers();
  }, [env, fetchCustomers]);

  // Add effect to update status value when a customer is selected
  useEffect(() => {
    if (statusCustomerId) {
      const selectedCustomer = customers.find(
        (customer) => customer.id === statusCustomerId
      );
      if (selectedCustomer) {
        setStatusValue(selectedCustomer.status || "active");
      }
    }
  }, [statusCustomerId, customers]);

  function resetAllStates() {
    setAddtionalApiKeyTag("");
    setAdditionalKeyResult("");
    setStatusCustomerId("");
    setStatusUpdateResult("");
  }

  useEffect(() => {
    // Reset selections when environment changes
    resetAllStates();
  }, [env]);

  async function addCustomerKey(customerId) {
    if (
      env === "prod" &&
      !window.confirm(
        "Are you sure you want to add a new customer key to the production environment?"
      )
    ) {
      return;
    }
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/add-customer-key`,
        {
          customerId,
          tag: addtionalApiKeyTag,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setAdditionalKeyResult(`New API Key added: ${response.data.api_key}`);
    } catch (error) {
      setAdditionalKeyResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  }

  async function updateCustomerStatus(customerId, status) {
    if (
      env === "prod" &&
      !window.confirm(
        `Are you sure you want to set customer ${customerId} to ${status} in the production environment?`
      )
    ) {
      return;
    }
    try {
      await axios.post(
        `${config.apiBaseUrl}/update-customer-status/${customerId}`,
        {
          status,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setStatusUpdateResult(`Customer status updated to: ${status}`);
      fetchCustomers(); // Refresh the customer list
    } catch (error) {
      setStatusUpdateResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  }

  // Helper function to get current customer status
  const getCurrentCustomerStatus = () => {
    if (!statusCustomerId) return null;
    const selectedCustomer = customers.find(
      (customer) => customer.id === statusCustomerId
    );
    return selectedCustomer ? selectedCustomer.status : null;
  };

  // Check if the status is actually changing
  const isStatusChanged = () => {
    const currentStatus = getCurrentCustomerStatus();
    return currentStatus !== null && currentStatus !== statusValue;
  };

  return (
    <div className="AdminPage">
      <h1>Admin Tools</h1>
      <EnvSwitcher onEnvironmentSwitch={switchEnvironment} />
      <br />
      <br />
      <div className="admin-columns">
        <div className="admin-column">
          <div className="container">
            <h2>Add Additional Key</h2>
            <div>
              <label>
                <select
                  value={customerIdInput}
                  onChange={(e) => setCustomerIdInput(e.target.value)}
                >
                  <option value="">Select a customer</option>
                  {customers.map((customer) => (
                    <option key={customer.id} value={customer.id}>
                      {customer.name}
                    </option>
                  ))}
                </select>
              </label>
              <br />
              <label>
                <input
                  type="text"
                  placeholder="API Key Tag"
                  value={addtionalApiKeyTag}
                  onChange={(e) => setAddtionalApiKeyTag(e.target.value)}
                />
              </label>
              <br />
              <button
                type="submit"
                onClick={() =>
                  addCustomerKey(customerIdInput, addtionalApiKeyTag)
                }
                disabled={!customerIdInput || !addtionalApiKeyTag}
              >
                Add Customer Key
              </button>
            </div>
            {additionalKeyResult && (
              <div className="result">{additionalKeyResult}</div>
            )}
          </div>
        </div>

        <div className="admin-column">
          <div className="container">
            <h2>Update Customer Status</h2>
            <div>
              <label>
                <select
                  value={statusCustomerId}
                  onChange={(e) => setStatusCustomerId(e.target.value)}
                >
                  <option value="">Select a customer</option>
                  {customers.map((customer) => (
                    <option key={customer.id} value={customer.id}>
                      {customer.name}
                    </option>
                  ))}
                </select>
              </label>
              <br />
              <label>
                Status:
                <select
                  value={statusValue}
                  onChange={(e) => setStatusValue(e.target.value)}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </label>
              <br />
              <button
                type="submit"
                onClick={() =>
                  updateCustomerStatus(statusCustomerId, statusValue)
                }
                disabled={!statusCustomerId || !isStatusChanged()}
              >
                Update Status
              </button>
            </div>
            {statusUpdateResult && (
              <div className="result">{statusUpdateResult}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminPage;

<style>
  {`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .spin {
    animation: spin 1s linear infinite;
  }
`}
</style>;
