import React, { useState, useEffect, useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  NavLink,
  useLocation,
} from "react-router-dom";
import Runs from "./components/Runs";
import Agents from "./components/Agents";
import LLMCalls from "./components/LlmCalls";
import GoogleAuth from "./GoogleAuth";
import AdminPage from "./components/AdminPage";
import WorkflowConfig from "./components/WorkflowConfig";
import CustomerCreation from "./components/CustomerCreation";
import CustomerCosts from "./components/CustomerCosts";
import "./App.css";
import { EnvironmentProvider } from "./contexts/EnvironmentContext";
import config from "./config";
import LiveDashboard from "./components/LiveDashboard";

function AppContent() {
  const location = useLocation();
  const [authState, setAuthState] = useState(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? "authenticated" : "unauthenticated";
  });
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSolutionsEngineer, setIsSolutionsEngineer] = useState(false);
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [selectedRun, setSelectedRun] = useState(null);
  const [selectedObject, setselectedObject] = useState(null);
  const [page, setPage] = useState(1);
  const [currentView, setCurrentView] = useState("Agents");
  const [isIpValid, setIsIpValid] = useState(false);
  const [isIpChecked, setIsIpChecked] = useState(false);
  const [privacyMode, setPrivacyMode] = useState(false);

  const handleLogout = useCallback(() => {
    setUser(null);
    setAuthState("unauthenticated");
    setIsAdmin(false);
    localStorage.removeItem("user");
    // Clear any other relevant state or storage
  }, []);

  const isAdminEmail = useCallback((email) => {
    const adminEmails = process.env.REACT_APP_ADMIN_EMAILS
      ? process.env.REACT_APP_ADMIN_EMAILS.split(",")
      : [];
    return adminEmails.includes(email);
  }, []);

  const isSolutionsEngineerEmail = useCallback((email) => {
    const solutionsEngineerEmails = process.env
      .REACT_APP_SOLUTIONS_ENGINEER_EMAILS
      ? process.env.REACT_APP_SOLUTIONS_ENGINEER_EMAILS.split(",")
      : [];
    return solutionsEngineerEmails.includes(email);
  }, []);

  useEffect(() => {
    const validateToken = async () => {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        const userObject = JSON.parse(storedUser);
        if (userObject.token) {
          try {
            const response = await fetch(
              `${config.apiBaseUrl}/validate-token`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ token: userObject.token }),
              }
            );

            // Check if the response is OK and contains JSON
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }

            const contentType = response.headers.get("content-type");
            if (!contentType || !contentType.includes("application/json")) {
              throw new Error("Oops! We haven't received a JSON response");
            }

            const data = await response.json();
            if (data.valid) {
              // Preserve the existing token instead of overwriting it
              setUser((prevUser) => ({
                ...prevUser,
                ...data.user,
                token: userObject.token,
              }));
              setAuthState("authenticated");
              setIsAdmin(isAdminEmail(data.user.email));
              setIsSolutionsEngineer(isSolutionsEngineerEmail(data.user.email));
            } else {
              handleLogout();
            }
          } catch (error) {
            console.error("Error validating token:", error.message);
            handleLogout();
          }
        } else {
          handleLogout();
        }
      } else {
        setAuthState("unauthenticated");
      }
    };

    validateToken();
  }, [handleLogout, isAdminEmail, isSolutionsEngineerEmail]);

  const handleLoginSuccess = useCallback(
    (userObject) => {
      const { email, token } = userObject; // Destructure to get email and token
      if (email) {
        setUser({ email, token }); // Store email and token in state
        setAuthState("authenticated");
        setIsAdmin(isAdminEmail(email));
        setIsSolutionsEngineer(isSolutionsEngineerEmail(email));
        localStorage.setItem("user", JSON.stringify({ email, token }));
      } else {
        console.error("User object does not have an email property");
      }
    },
    [isAdminEmail, isSolutionsEngineerEmail]
  );

  const handleLoginFailure = (error) => {
    console.error("Login failed:", error);
  };

  const handleRunClick = useCallback((run) => {
    if (run) {
      setSelectedRun(run);
      setselectedObject(null);
    } else {
      console.error("Selected run is undefined");
    }
  }, []);

  const handleAgentClick = (agent) => {
    setselectedObject(agent);
  };

  const handleRefresh = useCallback(() => {
    setSelectedRun(null);
    setselectedObject(null);
  }, []);

  const handleViewChange = (newView) => {
    setCurrentView(newView);
  };

  useEffect(() => {
    setselectedObject(null);
  }, [currentView]);

  const checkIpValidity = useCallback(async () => {
    try {
      const response = await fetch(`${config.apiBaseUrl}/display/validate-ip`);
      const data = await response.json();
      setIsIpValid(data?.message === "IP is allowed");
    } catch (error) {
      setIsIpValid(false);
    } finally {
      setIsIpChecked(true);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === "/live" && !user?.token) {
      checkIpValidity();
    } else if (location.pathname === "/live" && user?.token) {
      setIsIpChecked(true);
      setIsIpValid(true);
    }
  }, [checkIpValidity, user?.token, location.pathname]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const privacy = searchParams.get("privacy");
    setPrivacyMode(privacy === "true" || privacy === "1");
  }, [location.search]);

  return (
    <div className="App">
      {authState === "authenticated" && (
        <div className="hamburger-menu">
          <div className="hamburger-icon">☰</div>
          <nav className="nav-menu">
            <NavLink to="/">Home</NavLink>
            <NavLink to="/workflow-config">Workflow Config</NavLink>
            {isSolutionsEngineer && (
              <NavLink to="/customer-creation">Customer Creation</NavLink>
            )}
            {isAdmin && <NavLink to="/admin">Admin</NavLink>}
            <NavLink to="/customers">Customer Costs</NavLink>
            <NavLink to="/live">Live Activity</NavLink>
          </nav>
        </div>
      )}
      <div className="content">
        <Routes>
          <Route
            path="/live"
            element={
              <>
                {!isIpChecked ? (
                  <div className="loading">Checking access...</div>
                ) : isIpValid || authState === "authenticated" ? (
                  <LiveDashboard
                    googleAuthToken={user?.token}
                    onLogout={handleLogout}
                    privacyMode={privacyMode}
                  />
                ) : (
                  <div className="auth-container">
                    <GoogleAuth
                      onLoginSuccess={handleLoginSuccess}
                      onLoginFailure={handleLoginFailure}
                    />
                  </div>
                )}
              </>
            }
          />
          {authState === "authenticated" ? (
            <>
              <Route
                path="/admin"
                element={
                  isAdmin ? (
                    <AdminPage googleAuthToken={user.token} />
                  ) : (
                    <div>Access Denied</div>
                  )
                }
              />
              <Route
                path="/customers"
                element={<CustomerCosts googleAuthToken={user.token} />}
              />
              <Route
                path="/workflow-config"
                element={<WorkflowConfig googleAuthToken={user.token} />}
              />
              <Route
                path="/customer-creation"
                element={<CustomerCreation googleAuthToken={user.token} />}
              />
              <Route
                path="/"
                element={
                  <div className="app-container">
                    <Runs
                      onRunClick={handleRunClick}
                      selectedRun={selectedRun}
                      googleAuthToken={user.token}
                      page={page}
                      setPage={setPage}
                      onRefresh={handleRefresh}
                      onLogout={handleLogout}
                    />
                    <div className="Agents">
                      {selectedRun ? (
                        <Agents
                          run={selectedRun}
                          onAgentClick={handleAgentClick}
                          selectedObject={selectedObject}
                          googleAuthToken={user.token}
                          onViewChange={handleViewChange}
                        />
                      ) : (
                        <div className="agents-placeholder">
                          Select a run to see agents
                        </div>
                      )}
                    </div>
                    <div className="LLMCalls">
                      {selectedObject ? (
                        <LLMCalls
                          agent={selectedObject}
                          googleAuthToken={user.token}
                          currentView={currentView}
                        />
                      ) : (
                        <div className="llm-calls-placeholder">
                          {currentView === "observables"
                            ? "Select an observable to view details"
                            : currentView === "outputs"
                              ? "Select an output to view details"
                              : currentView === "errors"
                                ? "Select an error to view details"
                                : currentView === "agents"
                                  ? "Select an agent to see LLM calls and actions"
                                  : currentView === "identifiers"
                                    ? "Select an identifier to view details"
                                    : currentView === "consoleAgents"
                                      ? "Select a console agent to view details"
                                      : ""}
                        </div>
                      )}
                    </div>
                  </div>
                }
              />
            </>
          ) : authState === "unauthenticated" ? (
            <Route
              path="*"
              element={
                <div className="auth-container">
                  <GoogleAuth
                    onLoginSuccess={handleLoginSuccess}
                    onLoginFailure={handleLoginFailure}
                  />
                </div>
              }
            />
          ) : null}
        </Routes>
      </div>
    </div>
  );
}

function App() {
  return (
    <EnvironmentProvider>
      <Router>
        <AppContent />
      </Router>
    </EnvironmentProvider>
  );
}

export default App;
