import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { EnvironmentContext } from "../contexts/EnvironmentContext";
import config from "../config";

function CustomerCreation({ googleAuthToken }) {
  const [name, setName] = useState("");
  const [apiKeyTag, setApiKeyTag] = useState("");
  const [addtionalApiKeyTag, setAddtionalApiKeyTag] = useState("");
  const [newCustomerResult, setNewCustomerResult] = useState("");
  const [additionalKeyResult, setAdditionalKeyResult] = useState("");
  const [customerIdInput, setCustomerIdInput] = useState("");
  const [customers, setCustomers] = useState([]);
  const [apiKeyToValidate, setApiKeyToValidate] = useState("");
  const [validationResult, setValidationResult] = useState("");

  const { env, switchEnvironment } = useContext(EnvironmentContext);

  function resetAllStates() {
    setNewCustomerResult("");
    setAdditionalKeyResult("");
    setValidationResult("");
    setName("");
    setApiKeyTag("");
    setAddtionalApiKeyTag("");
    setCustomerIdInput("");
    setApiKeyToValidate("");
    setCustomers([]);
  }

  useEffect(() => {
    if (env !== "dev") {
      switchEnvironment("dev");
    }
  });

  useEffect(() => {
    // Reset selections when environment changes
    resetAllStates();
  }, [env]);

  const fetchCustomers = useCallback(async () => {
    try {
      const response = await axios.get(`${config.apiBaseUrl}/customers`, {
        headers: {
          Authorization: `Bearer ${googleAuthToken}`,
        },
      });
      setCustomers(response.data);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  }, [googleAuthToken]);

  useEffect(() => {
    fetchCustomers();
  }, [env, fetchCustomers]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!window.confirm("Are you sure you want to add a new customer?")) {
      return;
    }
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/add-customer`,
        {
          name,
          apiKeyTag,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setNewCustomerResult(
        `Customer added successfully. Development API Key: ${response.data.apiKey}`
      );
    } catch (error) {
      setNewCustomerResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  };

  async function addCustomerKey(customerId) {
    if (
      env === "prod" &&
      !window.confirm(
        "Are you sure you want to add a new customer key to the production environment?"
      )
    ) {
      return;
    }
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/add-customer-key`,
        {
          customerId,
          tag: addtionalApiKeyTag,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setAdditionalKeyResult(`New API Key added: ${response.data.api_key}`);
    } catch (error) {
      setAdditionalKeyResult(
        `Error: ${error.response?.data?.error || error.message}`
      );
    }
  }

  const validateApiKey = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${config.apiBaseUrl}/validate-api-key`,
        {
          apiKey: apiKeyToValidate,
        },
        {
          headers: {
            Authorization: `Bearer ${googleAuthToken}`,
          },
        }
      );
      setValidationResult(
        `Valid API key for customer: ${response.data.customerName} (Tag: ${response.data.tag} in ${response.data.environment} environment)`
      );
    } catch (error) {
      setValidationResult(`Invalid API key`);
    }
  };

  return (
    <div className="AdminPage">
      <h1>Customer Creation</h1>
      <br />
      <br />
      <div className="admin-columns">
        <div className="admin-column">
          <div className="container">
            <h2>Add New Customer</h2>
            <form onSubmit={handleSubmit}>
              <label>
                <input
                  type="text"
                  placeholder="Customer Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </label>
              <label>
                <input
                  type="text"
                  placeholder="API Key Tag ('default' if left blank)"
                  value={apiKeyTag}
                  onChange={(e) => setApiKeyTag(e.target.value)}
                />
              </label>
              <button type="submit">Add Customer</button>
            </form>
            {newCustomerResult && (
              <div className="result">{newCustomerResult}</div>
            )}
          </div>
          <div className="container">
            <h2>Add Additional Development Key</h2>
            <div>
              <label>
                <select
                  value={customerIdInput}
                  onChange={(e) => setCustomerIdInput(e.target.value)}
                >
                  <option value="">Select a customer</option>
                  {customers.map((customer) => (
                    <option key={customer.id} value={customer.id}>
                      {customer.name}
                    </option>
                  ))}
                </select>
              </label>
              <br />
              <label>
                <input
                  type="text"
                  placeholder="API Key Tag"
                  value={addtionalApiKeyTag}
                  onChange={(e) => setAddtionalApiKeyTag(e.target.value)}
                />
              </label>
              <br />
              <button
                type="submit"
                onClick={() =>
                  addCustomerKey(customerIdInput, addtionalApiKeyTag)
                }
                disabled={!customerIdInput || !addtionalApiKeyTag}
              >
                Add Customer Key
              </button>
            </div>
            {additionalKeyResult && (
              <div className="result">{additionalKeyResult}</div>
            )}
          </div>
        </div>
        <div className="admin-column">
          <div className="container">
            <h2>Validate API Key</h2>
            <form onSubmit={validateApiKey}>
              <label>
                <input
                  type="text"
                  placeholder="Enter API Key to validate"
                  value={apiKeyToValidate}
                  onChange={(e) => setApiKeyToValidate(e.target.value)}
                  required
                />
              </label>
              <button type="submit">Validate Key</button>
            </form>
            {validationResult && (
              <div className="result">{validationResult}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerCreation;

<style>
  {`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .spin {
    animation: spin 1s linear infinite;
  }
`}
</style>;
